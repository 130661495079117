<template>
    <div class="row" style="margin-top: 10px">
        <div v-show="!tiendaAsiganda">
            <div class="col-md-12">
                <h1 class="text-center text-danger tx-bold">{{Tienda}}</h1>
            </div>
        </div>
        <div v-show="tiendaAsiganda">
            <div class="col-md-12">
                <div class="card custom-card">
                    <div class="card-title">
                        <div class="row">
                            <div class="col-md-9">
                                <h2 class="main-content-title tx-24 mg-b-5"> <img alt="Image" src="/theme/img/brand/logo.png" width="110px"> &nbsp;&nbsp;&nbsp;{{fecha}} - {{Tienda}}</h2>
                            </div>
                            <div class="col-md-3">
                                <button class="btn ripple btn-success btn-with-icon" @click="openScanQr"
                                        data-effect="effect-just-me" data-toggle="modal" href="#myModal6">
                                    <i class="fas fa-qrcode"></i> Escanear QR
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div><i class="fas fa-user-astronaut mr-1 dash-icon"></i></div>
                        <!-- ventas cajeros -->
                        <ventas-cajeros ref="ventasR" :ip-app="ipApp" :ip-print="ipPrinter" :sucursal-id="parseInt(SucursalId)"></ventas-cajeros>
                        <img alt="Image" src="/theme/img/brand/BANNER.jpg" class="img-fluid">
                    </div>
                </div>
            </div>
            <!-- modal qr -->
            <div class="modal" id="myModal6" tabindex="-1" :class="{'show':shoModalAlta}">
                <div class="modal-dialog modal-dialog-centered modal-lg " role="document" >
                    <div class="modal-content modal-content-demo">
                        <div class="modal-header">
                            <h6 class="modal-title">ESCANEAR QR</h6>
                            <button id="close" aria-label="Close" class="close" data-dismiss="modal" type="button" @click="cancelScan"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div class="modal-body">
                            <p class="decode-result text-center">Mensaje QR: <b>{{ result }}</b></p>
                            <p class="decode-result text-center" v-if="encontrado">Apodo: <b>{{ Apodo }}</b></p>
                            <p class="decode-result text-center" v-if="encontrado">Email: <b>{{ Email }}</b></p>
                            <p class="decode-result text-center" v-if="encontrado">Cumpleaños: <b>{{ BirthDay }}</b></p>

                            <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" v-if="!destroyed">
                                <div v-show="showScanConfirmation" class="scan-confirmation">
                                    <img :src="'/theme/img/brand/logo.png'" alt="Checkmark" width="128px" />
                                </div>
                                <div class="loading-indicator" v-if="loading">
                                    Esperando... Siga las instrucciones del navegador!
                                </div>
                                <qrcode-stream :camera="camera" @init="onInit">
                                    <button @click="switchCamera">
                                        <img :src="'/theme/img/brand/switch-camera.png'" alt="switch camera" height="30px">
                                    </button>
                                </qrcode-stream>
                            </qrcode-stream>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal" @click="cancelScan" ref="buttonCancel">
                                Cancelar Escaneo<i class="fa fa-times"></i>
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader';

export default {
    components: {
        QrcodeStream,
        QrcodeDropZone,
        QrcodeCapture
    },
    name: "menuCajero",
    data(){
        return{
            fecha:null,
            shoModalAlta:false,
            tiendaAsiganda:false,
            camera: 'rear',
            result: null,
            showScanConfirmation: false,
            destroyed:true,
            loading: false,
            Tienda:'',
            SucursalId:0,
            ipApp: '0',
            ipPrinter: '0',
            Apodo:'',
            Email:'',
            BirthDay:'',
            idCliente:0,
            encontrado:false,
            noRearCamera: false,
            noFrontCamera: false

        }
    },
    mounted() {
        var currentdate = new Date();
        var mes = (currentdate.getMonth()+1).toString().length === 1 ? '0'+(currentdate.getMonth()+1).toString() : (currentdate.getMonth()+1).toString();
        var dia = (currentdate.getDate()+1).toString().length === 1 ? '0'+(currentdate.getDate()+1).toString() : (currentdate.getDate()+1).toString();
        this.fecha = currentdate.getFullYear() + "-"
            + mes  + "-"
            + dia + " "
            + currentdate.getHours() + ":"
            + currentdate.getMinutes();
        this.validateTienda();
    },
    methods:{
        switchCamera () {
            switch (this.camera) {
                case 'front':
                    this.camera = 'rear'
                    break
                case 'rear':
                    this.camera = 'front'
                    break
            }
        },
        async onInit (promise) {
            this.loading = true
            this.encontrado = false
            try {
                await promise
            } catch (e) {
                const triedFrontCamera = this.camera === 'front'
                const triedRearCamera = this.camera === 'rear'

                const cameraMissingError = e.name === 'OverconstrainedError'

                if (triedRearCamera && cameraMissingError) {
                    this.noRearCamera = true
                }

                if (triedFrontCamera && cameraMissingError) {
                    this.noFrontCamera = true
                }
                console.error(e)
            } finally {
                this.showScanConfirmation = this.camera === "off"
            }
        },
        async onDecode (content) {
            let $this = this;
            this.result = content
            this.loading = false
            var id = this.result.replace('clienteId:', '');
            await axios.post('/api/Users/getUserByQr',{
                id
            })
            .then(function (r) {
                if (r.data.Success) {
                    $this.Apodo = r.data.Apodo;
                    $this.Email = r.data.Email;
                    $this.BirthDay = r.data.BirthDay;
                    $this.idCliente = r.data.Id;
                    $this.encontrado = true;
                }
            })
            .catch(function (error) {
                $this.$toastMessage('error','Error: ' + error,5000,'center');
            });
            this.pause()
            await this.timeout(1900)
            this.unpause()
            this.destroyed = true;
            this.$refs.ventasR.clienteFind = true;
            this.$refs.ventasR.dataUser = this.Apodo +' '+ this.BirthDay;
            await this.$refs.ventasR.getUserByQr(this.idCliente);
            this.$refs.buttonCancel.click();
        },
        unpause () {
            this.camera = 'auto'
        },
        pause () {
            this.camera = 'off'
        },
        timeout (ms) {
            return new Promise(resolve => {
                window.setTimeout(resolve, ms)
            })
        },
        openScanQr(){
            this.destroyed = false;
        },
        cancelScan(){
            this.shoModalAlta = !this.shoModalAlta;
            this.destroyed = true;
        },
        async validateTienda(){
            var response = await this.$peticionesApi('/api/Ventas/getTiendaAsignada',{});
            if (response !== null){
                if (response.Success){
                    this.Tienda = response.Sucursal;
                    this.SucursalId = response.SucursalId;
                    this.tiendaAsiganda = response.Success;
                    this.ipApp = response.Aplicacion;
                    this.ipPrinter = response.Impresora;
                }else{
                    this.Tienda = response.Mensaje;
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
