<template>
    <div>
        <!-- Modal effects -->
        <div class="modal" id="modalEditUser" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Editar Usuario <i class="fa fa-user-edit"></i></h6>
                        <button ref="closeModalEdit" class="btn ripple btn-secondary text-right btn-with-icon" data-dismiss="modal" type="button">Cancelar &nbsp;&nbsp;<i class="fa fa-times"></i></button>
                    </div>
                    <div class="modal-body">
                        <form-wizard @on-complete="onComplete"
                                     @on-loading="setLoading"
                                     @on-validate="handleValidation"
                                     @on-error="handleErrorMessage" ref="formEditUser"
                                     shape="circle" back-button-text="Anterior" next-button-text="Siguiente" finish-button-text="Actualizar"
                                     color="#4a2c58" title="" subtitle="" :start-index="0"
                                     error-color="#e74c3c">
                            <tab-content title="Datos personales"
                                         :before-change="validateDataPersonal"
                                         icon="fa fa-book">
                                <div class="row">
                                    <div class="col-md-6">
                                        <p class="mg-b-10">Nombre(s)</p>
                                        <input type="text" class="form-control" :class="{ 'is-invalid state-invalid': $v.dataEditUser.nombres.$invalid }" v-model="dataEditUser.nombres"  placeholder="Ingresa nombre(s)">
                                        <div class="invalid-feedback">{{!$v.dataEditUser.nombres.required ? 'Ingrese nombre(s)' : !$v.dataEditUser.nombres.minLength ? 'Longitud minima ' + $v.dataEditUser.nombres.$params.minLength.min : 'error'}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="mg-b-10">Apellido Paterno</p>
                                        <input type="text" class="form-control" :class="{ 'is-invalid state-invalid': $v.dataEditUser.paterno.$invalid }" v-model="dataEditUser.paterno"  placeholder="Ingresa apellido paterno">
                                        <div class="invalid-feedback">{{!$v.dataEditUser.paterno.required ? 'Ingrese apellido paterno' : !$v.dataEditUser.paterno.minLength ? 'Longitud minima ' + $v.dataEditUser.paterno.$params.minLength.min : 'error'}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p class="mg-b-10">Apellido Materno</p>
                                        <input type="text" class="form-control" :class="{ 'is-invalid state-invalid': $v.dataEditUser.materno.$invalid }" v-model="dataEditUser.materno"  placeholder="Ingresa apellido materno">
                                        <div class="invalid-feedback">{{!$v.dataEditUser.materno.required ? 'Ingrese apellido materno' : !$v.dataEditUser.materno.minLength ? 'Longitud minima ' + $v.dataEditUser.materno.$params.minLength.min : 'error'}}</div>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="mg-b-10">Teléfono</p>
                                        <input type="text" class="form-control" :class="{ 'is-invalid state-invalid': $v.dataEditUser.tel.$invalid }" v-model="dataEditUser.tel"  placeholder="Ingresa número empleado" @keypress="isNumber($event)" maxlength="10">
                                        <div class="invalid-feedback">{{!$v.dataEditUser.tel.minLength ? 'Longitud minima ' + $v.dataEditUser.tel.$params.minLength.min : 'error'}}</div>
                                    </div>
                                </div>
                                <br><br>
                            </tab-content>
                            <tab-content title="Datos Empresa" :before-change="validateDataEmpresa"
                                         icon="fa fa-cogs">
                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-4">
                                        <p class="mg-b-10">Rol:</p>
                                        <multiselect v-model="dataEditUser.tipo" :options="optionsTipo" select-label="Click para seleccionar"
                                                     deselect-label="No se puede quitar" placeholder="Seleccione..." :close-on-select="true" :custom-label="title" :allow-empty="false"
                                                     :clear-on-select="true" :preselect-first="true" :class="{ 'is-danger': !Object.keys(dataEditUser.tipo).length }" track-by="title">
                                            <template slot="singleLabel" slot-scope="props">
                                                <span class="option__title">{{ props.option.title }}</span>
                                            </template>
                                            <template slot="option" slot-scope="props">
                                                <span class="option__title">{{ props.option.title }}</span>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <div class="col-md-5">
                                        <p class="mg-b-10">Ciudad</p>
                                        <multiselect v-model="dataEditUser.ciudad" :options="optionsCiudades" select-label="Click para seleccionar"
                                                     deselect-label="No se puede quitar" placeholder="Seleccione..." :close-on-select="true" :allow-empty="false"
                                                     :class="{ 'is-danger': !Object.keys(dataEditUser.ciudad).length }" track-by="title" :custom-label="title">
                                            <template slot="singleLabel" slot-scope="props">
                                                <span class="option__title">{{ props.option.title }}</span>
                                            </template>
                                            <template slot="option" slot-scope="props">
                                                <span class="option__title">{{ props.option.title }}</span>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <div class="col-md-1"></div>
                                </div><br>
                                <br><br>
                            </tab-content>

                            <div class="row" v-if="loadingWizard">
                                <div class="col-md-12 text-center">
                                    <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                                    <p><b>Validando información por favor espere!</b></p>
                                </div>
                            </div>
                            <div class="row" v-if="errorMsg">
                                <div class="col-md-12 text-center"><br><br>
                                    <span class="text-danger">{{errorMsg}}</span>
                                </div>
                            </div>
                            <img alt="Image" src="/theme/img/brand/BANNER.jpg" class="img-fluid">
                        </form-wizard>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal effects-->
    </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

export default {
    name: "editUser",
    data(){
        return{
            loadingWizard: false,
            errorMsg: null,
            dataEditUser:{
                cveusr:'',
                username: '',
                cveemp: '',
                nombres:'',
                paterno:'',
                materno:'',
                tel:'',
                ciudad: {},
                tipo: {},
                agencia:{},
            },
            value: null,
            options: ['list', 'of', 'options'],
            optionsEstatus: [{title:'Activo', value:1},{title:'Inactivo',value:0}],
            optionsTipo: [],
            optionsCiudades: [],
            optionsAgencias: [],
        }
    },
    validations: {
        dataEditUser: {
            username: {
                required, minLength: minLength(3)
            },
            cveemp: {
                required, minLength: minLength(1)
            },
            nombres: {
                required, minLength: minLength(4)
            },
            paterno: {
                required, minLength: minLength(1)
            },
            materno: {
                required, minLength: minLength(1)
            },
            tel: {
                minLength: minLength(10)
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        setData(data){
            this.dataEditUser.cveusr = data.Cveentusr;
            this.dataEditUser.cveemp = data.Idemp;
            this.dataEditUser.username = data.Usrentusr;
            this.dataEditUser.nombres = data.Name;
            this.dataEditUser.paterno = data.Apepat;
            this.dataEditUser.materno = data.Apemat;
            this.dataEditUser.tel = data.Telefono;
            this.dataEditUser.ciudad = {title: data.Ciudad, value:data.CveCiudad};
            this.$refs.formEditUser.reset();
        },
        getData(){let $this = this;
            axios.post('/api/Users/getOptionsNewUsuarios')
                .then(r => {
                        if (r.data.Success){
                            $this.optionsCiudades = r.data.Ciudades;
                            $this.optionsTipo = r.data.Roles;
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        onComplete(){let $this = this;
            axios.post('/api/Users/saveEditUser',{dataUser: this.dataEditUser})
                .then(r => {
                        if (r.data.Success){
                            $this.$refs.closeModalEdit.click();
                            $this.$refs.formEditUser.reset();
                            $this.$toastMessage('success', r.data.Mensaje,6000,'top-right');
                            this.$emit('editFinish')
                        }else{
                            $this.$toastMessage('error', r.data.Mensaje,6000,'top-right');
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        setLoading(value){
            this.loadingWizard = value
        },
        handleValidation(isValid, tabIndex){
            console.log('Tab: '+tabIndex+ ' valid: '+isValid)
        },
        handleErrorMessage(errorMsg){
            this.errorMsg = errorMsg
        },
        validateDataUser() {let $this = this;
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if(this.$v.dataEditUser.username.$invalid){
                        reject('Ingrese un usuario valido!');
                    }else if(this.$v.dataEditUser.cveemp.$invalid){
                        reject('Ingrese una clave valida!');
                    }
                    else{
                        axios.post('/api/Users/validateUserName',{username: this.dataEditUser.username, cveusr: this.dataEditUser.cveusr})
                            .then(r => {
                                    if (r.data.Success){
                                        resolve(true);
                                    }else{
                                        $this.$toastMessage('error','Error: Usuario ya existe, cambie el nombre de usuario!',6000,'top-right');
                                        reject('Error: Usuario ya existe, cambie el nombre de usuario!');
                                    }
                                },
                                error => {
                                    reject('Error: ' + error +' Por favor envia este error al área de soporte!');
                                $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                            }
                            );
                    }
                }, 1000);
            })
        },
        validateDataPersonal() {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if(this.$v.dataEditUser.nombres.$invalid){
                        reject('Ingrese un nombre valido!');
                    }else if(this.$v.dataEditUser.paterno.$invalid){
                        reject('Ingrese un apellido paterno valido!');
                    }
                    else if(this.$v.dataEditUser.materno.$invalid){
                        reject('Ingrese un apellido materno valido!');
                    }else if(this.$v.dataEditUser.tel.$invalid){
                        reject('Ingrese un número de celular valido!');
                    }
                    else{
                        resolve(true)
                    }
                }, 1000)
            });
        },
        validateDataEmpresa(){
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if(!Object.keys(this.dataEditUser.tipo).length){
                        reject('Seleccione un Rol valido!');
                    }
                    else if(!Object.keys(this.dataEditUser.ciudad).length){
                        reject('Seleccione una ciudad valida!');
                    }
                    else{
                        resolve(true)
                    }
                }, 1000);
            });
        },
       title ({ title }) {
            return `${title}`
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    }
}
</script>

<style>
.vue-form-wizard .wizard-nav-pills > li > a {
    color: #2da830;
}
.multiselect__option--highlight{
    background: #2da830 !important;
}

.is-danger .multiselect__tags {
    border-color: red;
}
</style>
