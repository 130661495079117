/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
import Permissions from './mixins/Permissions';
Vue.mixin(Permissions);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent').default);
////////////////// ADMINISTRACIÓN
//MENU
Vue.component('sidemenu-component', require('./app/menu/sidemenu').default); // table data
//// USUARIOS
Vue.component('tableusers-component', require('./app/Administracion/Usuarios/tableUsers').default); // table data
Vue.component('modalnewuser-component', require('./app/Administracion/Usuarios/newUser').default); // modal new user
Vue.component('modaleditser-component', require('./app/Administracion/Usuarios/editUser').default); // edit user
//// PERMISOS
Vue.component('permisos-component', require('./app/Administracion/Permisos/permisosUsuarios').default); // permisos moët app web
//// CLIENTES
Vue.component('registro-clientes', require('./app/Clientes/registro').default); // registro de nuevos clientes
// // VENTAS TABLA
Vue.component('tabla-ventas', require('./app/Administracion/Ventas/tablaVentas').default); // registro de las ventas realizadas
// // CLIENTES TABLA
Vue.component('tabla-clientes', require('./app/Administracion/Clientess/clientesTable').default); // registro de nuevos clientes
Vue.component('modal-clientespromociones', require('./app/Administracion/Clientess/clientePromociones').default); // registro de promociones del clientes
//// CAJEROS
Vue.component('menu-cajeros', require('./app/Cajeros/menu').default); // contenedor modulo menu cajeos
Vue.component('ventas-cajeros', require('./app/Cajeros/ventas').default); // ventas
//// SUCURSALES
Vue.component('registro-sucursales', require('./app/Administracion/Sucursales/contenedor').default); // contenedor modulo sucursales
Vue.component('sucursales-distribucion', require('./app/Administracion/Sucursales/distribucion').default); // registro de ciudades
Vue.component('sucursales-admin', require('./app/Administracion/Sucursales/sucursales').default); // registro de sucursales
//// PRODUCTOS
Vue.component('productos-admin', require('./app/Administracion/Productos/productos').default); // admin productos
//// GENERALES
Vue.component('select-anio', require('./components/generales/anios').default); // registro de sucursales
Vue.component('select-mes', require('./components/generales/mes').default); // registro de sucursales
Vue.component('select-dia', require('./components/generales/dia').default); // registro de sucursales
Vue.component('select-tienda', require('./components/generales/sucursales').default); // select sucursales
Vue.component('select-apodo', require('./components/generales/apodos').default); // select sucursales
Vue.component('select-email', require('./components/generales/emails').default); // select sucursales
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//// GLOGAL COMPONENTES
//VUE TABLES
import {ServerTable, ClientTable, Event} from 'vue-tables-2';
Vue.use(ClientTable,ServerTable);
//SWEET ALERT 2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
// VUE TOOLTIP
import VTooltip from 'v-tooltip';
Vue.use(VTooltip);
//FORM WIZARD
import VueFormWizard from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
Vue.use(VueFormWizard);
// VUELIDATE
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);
// VUE MULTI SELECT
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
Vue.component('multiselect', Multiselect);
// VUE DATEPICKER
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
Vue.component('DatePicker', DatePicker);
//VUE NAV TABS
import VueTabs from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';
Vue.use(VueTabs);
import VueHighcharts from 'highcharts-vue';
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import exportingData from 'highcharts/modules/export-data';
import loadDrillDown from 'highcharts/modules/drilldown';
import loadSolidGauge from 'highcharts/modules/solid-gauge';
import Highcharts3D from 'highcharts/highcharts-3d';
import heatmap from 'highcharts/modules/heatmap';
import pyramid3d from 'highcharts/modules/pyramid3d';
import funnel from 'highcharts/modules/funnel';
import funnel3d from 'highcharts/modules/funnel3d';
import cylinder from 'highcharts/modules/cylinder';
import loadHighchartsMore from 'highcharts/highcharts-more';
import accesibility from 'highcharts/modules/accessibility';
loadDrillDown(Highcharts);
exportingInit(Highcharts);
exportingData(Highcharts);
Highcharts3D(Highcharts);
heatmap(Highcharts);
loadHighchartsMore(Highcharts);
loadSolidGauge(Highcharts);
funnel(Highcharts);
funnel3d(Highcharts);
cylinder(Highcharts);
pyramid3d(Highcharts);
accesibility(Highcharts);
Vue.use(VueHighcharts, { Highcharts });
/// v-select
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);
// momment
Vue.use(require('vue-moment'));
/// zoom image
import VueZoomer from 'vue-zoomer';
Vue.use(VueZoomer);
//pretty checkbox
import PrettyCheckbox from 'pretty-checkbox-vue';
import 'pretty-checkbox/src/pretty-checkbox.scss';
Vue.use(PrettyCheckbox);
import VCalendar from 'v-calendar';
Vue.use(VCalendar);
import VueConfetti from 'vue-confetti';
Vue.use(VueConfetti);




import axios from 'axios';
axios.defaults.headers.post['Authorization'] = Token.Token;

///////////////////////////////////////// --------------------> INICIA EL REGISTRO DE FUNCIONES GLOBALES
Vue.prototype.$toastMessage = function toastMessage(type, text, duration,position) {
    this.Toast = this.$swal.mixin({
        toast: true, position: position, timerProgressBar: true,
        showConfirmButton: false, timer: duration
    });
    this.Toast.fire({
        icon: type,
        title: text
    });
}
//// PETICIONES POST WEBSERVICES YOGOCUP
Vue.prototype.$peticionesApi = async function(url, vars) {
    try {
        const resData = await axios.post(url, {data: vars});
        return resData.data;
    } catch (error) {
        let mensaje = "", type = "";
        switch (error.response.status) {
            case 500:
                mensaje = `Error en la petición al servidor, enviar esta pantalla de error al area de IT. Url: ${url}`;
                type = "error";
                break
            case 403:
                mensaje = `Sesión finalizada, por favor inicie sesión nuevamente. :)`;
                type = "warning";
                break
            default:
                mensaje = `Error en la petición al servidor, enviar esta pantalla de error al area de IT. Url: ${url} <br> Estatus: ${error.response.status}`;
                type = "error";
        }
        this.$toastMessage(type, `Error: ${mensaje}`, 4000, 'center');
        if (error.response.status === 403) {
            setTimeout(window.location.href = "/login", 45000);
        }
        return false;
    }
}
Vue.prototype.$peticionesApiTicket = async function(url, data, venta, cupones) {
    try {
        const resData = await axios.post(url, {data: data,venta: venta, cupones:cupones});
        return resData.data;
    } catch (error) {
        let mensaje = "", type = "";
        switch (error.response.status) {
            case 500:
                mensaje = `Error en la petición al servidor, enviar esta pantalla de error al area de IT. Url: ${url}`;
                type = "error";
                break
            case 403:
                mensaje = `Sesión finalizada, por favor inicie sesión nuevamente. :)`;
                type = "warning";
                break
            default:
                mensaje = `Error en la petición al servidor, enviar esta pantalla de error al area de IT. Url: ${url} <br> Estatus: ${error.response.status}`;
                type = "error";
        }
        this.$toastMessage(type, `Error: ${mensaje}`, 4000, 'center');
        if (error.response.status === 403) {
            setTimeout(window.location.href = "/login", 45000);
        }
        return null;
    }
}
//// PETICIONES POST WEBSERVICES ATENEA FILES DOWNLOAD
Vue.prototype.$peticionesApiFilesDownload = async function(url, nameFile, dataProccess) {
    try {
        await axios({
            method: 'post',
            url: url,
            responseType: 'arraybuffer',
            data: { data: dataProccess } }).then(r => {
            let blob = new Blob([r.data], { type: 'application/xlsx' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = nameFile+'.xlsx'
            link.click()
        });

    } catch (error) {
        let mensaje = "", type = "";
        switch (error.response.status) {
            case 500:
                mensaje = `Error en la petición al servidor, enviar esta pantalla de error al area de IT. Url: ${url}`;
                type = "error";
                break
            case 403:
                mensaje = `Sesión finalizada, por favor inicie sesión nuevamente. :)`;
                type = "warning";
                break
            default:
                mensaje = `Error en la petición al servidor, enviar esta pantalla de error al area de IT. Url: ${url} <br> Estatus: ${error.response.status}`;
                type = "error";
        }
        this.$toastMessage(type, `Error: ${mensaje}`, 4000, 'center');
        if (error.response.status === 403) {
            setTimeout(window.location.href = "/login", 45000);
        }
        return [];
    }
}

///////////////////////////////////////// --------------------> FINALIZA EL REGISTRO DE FUNCIONES GLOBALES

const app = new Vue({
    el: '#app',
});
