<template>
    <div>
        <div class="row">
            <button style="display:none" type="button" class="modal-effect btn ripple btn-outline-info"
                    data-effect="effect-super-scaled" data-toggle="modal" href="#modalPromociones"
                    ref="buttonModalPromociones">
                Editar Usuario
            </button>
            <div class="col-md-3">
                <button v-if="typeUsers===1" type="button" class="btn ripple btn-outline-danger" @click="getData(0)" ><i class="fe fe-eye"></i> Inactivos</button>
                <button v-if="typeUsers===0" type="button" class="btn ripple btn-outline-success" @click="getData(1)" ><i class="fe fe-eye"></i> Activos</button>
            </div>
            <div class="col-md-3">

            </div>
            <div class="col-md-3">
            </div>
            <div class="col-md-3">
                <button type="button" class="btn ripple btn-outline-success" @click="ExportFile" v-if="!showLoadDownload"><i class="far fa-file-excel"></i> Exportar</button>
                <button type="button" class="btn ripple btn-outline-info" @click="getData(typeUsers)"><i class="fas fa-retweet"></i> Actualizar</button>
                <div class="row" v-if="showLoadDownload">
                    <div class="col-md-12 text-center"><br><br>
                        <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                        <p><b>Preparando información espere...</b></p>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <v-client-table :data="tableData" :columns="columns" :options="options" ref="UsersTable" v-show="!showLoad">
            <div slot="Total" slot-scope="props">
                <div class="text-center">
                    <strong style="cursor: pointer" @click="getPromociones(props.row.Id)">{{props.row.Total}}</strong><br>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="text-center tx-bold tx-success">
                            {{props.row.Cambiados}}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text-center tx-bold tx-danger">
                            {{props.row.Vencidos}}
                        </div>
                    </div>
                </div>
            </div>
            <div slot="Estatus" slot-scope="props">
                <span v-if="props.row.Estatus === 'Activo'" class="badge badge-success ripple" @click="ChangeStatus(props.row.Id,0)">Activo</span>
                <span v-else class="badge badge-danger ripple" @click="ChangeStatus(props.row.Id,1)">Inactivo</span>
            </div>
        </v-client-table>
        <div class="row" v-if="showLoad">
            <div class="col-md-12 text-center"><br><br>
                <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                <p><b>Buscando información por favor espere!</b></p>
            </div>
        </div>
        <modal-clientespromociones ref="promocionesModal"></modal-clientespromociones>
    </div>
</template>

<script>
export default {
name: "clientesTable",
    data(){
        return{
            tipoUserEdit: {},
            rolUserEdit: {},
            typeUsers:1,
            msg:"asdf",
            showLoad:true,
            showLoadDownload:false,
            columns: ['Id', 'Apodo','Email','Cumpleaños','Tel','Estatus','Fecha','FechaVisita','Compra','Sellos','Total'],
            tableData: [],
            optionsTipo: [],
            optionsRol: [],
            options: {
                // see the options API
                footerHeadings:false,
                filterByColumn:true,
                perPage:10,
                perPageValues: [10,15,25,45,50],
                filterable: ['Apodo','Email','Cumpleaños'],
                sortable: ['Apodo','Email','Cumpleaños'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {Agencia: [], Tipo: [], Rol: [],},
                headings: {
                    Estatus: 'Estatus',
                    FechaVisita: 'Fecha Visita',
                    Nombre: 'Nombre Empleado',
                    Agencia: 'Agencia',
                    Compra: 'Compras',
                    FU: 'Fecha Update App',
                    Fecha: 'Fecha Alta',
                    Rol: 'Rol',
                },
                columnsClasses: {
                    Estatus: 'text-center',
                    Promociones: 'text-center tx-bold',
                    FechaVisita: 'text-center tx-bold',
                    Apodo: 'text-justify tx-bold',
                    Tipo: 'text-center',
                    Ciudad: 'text-center',
                    Agencia: 'text-center',
                    Compra: 'text-center tx-bold',
                    Sellos: 'text-center tx-bold',
                    FU: 'text-center',
                    Fecha: 'text-center',
                    Rol: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Recopilando información, por favor espere...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            }
        }
    },
    mounted() {
        this.getData(this.typeUsers);
    },
    methods:{
        getPromociones(id){
            this.$refs.promocionesModal.getData(id);
            this.$refs.buttonModalPromociones.click();
        },
        getData(type){let $this = this;
            this.typeUsers = type;this.showLoad=true;
             axios.post('/api/Clientes/getClientes',{typeUsers:type})
                 .then(r => {
                     if (r.data.Success){
                         $this.tableData = [];
                         $this.tableData = r.data.Data;
                         $this.showLoad=false;
                     }else{
                         $this.tableData = [];
                         $this.tableData = r.data.Data;
                         $this.showLoad=false;
                         $this.$toastMessage('error',r.data.Mensaje,6000,'center')
                     }
                 },
                 error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
             );
        },
        DeleteAllSeriales(){let $this = this;
            this.$swal.fire({
                title: '¿Eliminar Todos Los Seriales?',
                text: "Esto solo se realiza en una actualización",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, eliminar!',
                cancelButtonText: 'No, cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/api/Users/deleteAllSeriales')
                        .then(r => {
                                if (r.data.Success){
                                    $this.$swal.fire(
                                        'Eliminados!',
                                        'Ahora pueden ingresar con otro dispositivo.',
                                        'success'
                                    );
                                    $this.getData($this.typeUsers);
                                }else{
                                    $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                }
                            },
                            error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                        );
                }
            });
        },
        ChangeStatus(clave,type){let $this = this;
            var id = this.getIdx(clave);
            this.$swal.fire({
                title: type === 0 ? '¿Desactivar Cliente?' : '¿Activar Cliente?',
                text: type === 0 ? 'Al aceptar, no podrá generar cupones' : 'Al aceptar, se le permitirá de nuevo la generación de cupones',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: type === 0 ? 'Desactivar' : 'Activar',
                cancelButtonText: 'Cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/api/Clientes/changeEstatus',{clave: clave, stt: type})
                        .then(r => {
                                if (r.data.Success){
                                    $this.$swal.fire(
                                        'Exito!',
                                        'Acción realizada satisfactoriamente.',
                                        'success'
                                    );
                                    $this.tableData[id]['Estatus'] = type === 0 ? 'Inactivo' : 'Activo';
                                }else{
                                    $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                }
                            },
                            error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                        );
                }
            });
        },
        getIdx(clave){let $this = this;
            var idx = 0;
            for (var i = 0; i < this.tableData.length; i++){
                if (parseInt(clave) === parseInt($this.tableData[i].Id)){
                    idx = i;
                }
            }
            return idx;
        },
        async ExportFile(){
            this.showLoad=true;
            await this.$peticionesApiFilesDownload('/api/Clientes/exportExcel','ClientesYogocup',this.tableData);
            this.showLoad=false;
        },
    }
}
</script>

<style scoped>
tbody {
    display:block;
    height:400px;
    overflow:auto;
}

thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;/* even columns width , fix width of table too*/
}

thead {
    width: calc( 100% )/* scrollbar is average 1em/16px width, remove it from thead width */
}
</style>
