
<script>
export default {
    methods: {
        $can(permissionName) {
            var checkP = false;
            permissionName.forEach(function (value,kye){
                if (!checkP) {
                    checkP = Permissions.indexOf(value) !== -1;
                }
            })
            return checkP;
        },
        $rol(rol) {
            var checkP = false;
            rol.forEach(function (value,kye){
                if (!checkP){
                    checkP = Rol.indexOf(value) !== -1;
                }

            })
            return checkP;
        },
    },
};
</script>
