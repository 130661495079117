<template>
    <div>
        <v-select :multiple="false" label="label" :filterable="false" :options="optionsApodos" @search="onSearch" v-model="selectedApodo"
                  :placeholder="'Ingresa el '+placeholderm" @input="setCambio">
            <template slot="no-options">
                No existe apodo :( con los criterios buscados.
            </template>

        </v-select>
    </div>
</template>

<script>
export default {
    name: "apodos",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedApodo:null,
            optionsApodos:[],
        }
    },
    methods:{
        onSearch(search, loading) {
            if(search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search: _.debounce((loading, search, vm) => {
            axios
                .post('/api/Clientes/getApodos',{apodo:search})
                .then(function (r){
                    vm.optionsApodos = r.data;
                    loading(false);
                });
        }, 350),
        getApodos(){ let $this = this;
            this.selectedApodo = null;
            axios
                .post('/api/Clientes/getApodos')
                .then(function (r){
                    $this.optionsApodos = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioApodo');
        }
    },
    mounted() {
        //this.getApodos();
    }
}
</script>

<style scoped>

</style>
