import { render, staticRenderFns } from "./dia.vue?vue&type=template&id=9f74c6ee&scoped=true&"
import script from "./dia.vue?vue&type=script&lang=js&"
export * from "./dia.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f74c6ee",
  null
  
)

export default component.exports