<template>
    <div>
        <v-select :multiple="false" label="label" :filterable="false" :options="options" @search="onSearch" v-model="selectedEmail"
                  :placeholder="'Ingresa el '+placeholderm" @input="setCambio">
            <template slot="no-options">
                No existe email :( con los criterios buscados.
            </template>

        </v-select>
    </div>
</template>

<script>
export default {
    name: "emails",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedEmail:null,
            optionsEmails:[],
            options:[],
        }
    },
    methods:{
        onSearch(search, loading) {
            if(search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search: _.debounce((loading, search, vm) => {
            axios
                .post('/api/Clientes/getEmails',{email:search})
                .then(function (r){
                    vm.options = r.data;
                    loading(false);
                });
        }, 350),
        getEmails(){ let $this = this;
            this.selectedEmail = null;
            axios
                .post('/api/Clientes/getEmails')
                .then(function (r){
                    $this.optionsEmails = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioEmail');
        }
    },
    mounted() {
        //this.getEmails();
    }
}
</script>

<style scoped>

</style>
