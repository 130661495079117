<template>
    <div>
        <v-select :multiple="false" :options="optionsAnios" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedAnios" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "anios",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedAnios:null,
            optionsAnios:[]
        }
    },
    mounted() {
       var anio = new Date().getFullYear();
       anio = anio -17;
        for (let i = 0; i < 51; i++) {
            this.optionsAnios.push(
                {'label': anio - i, 'value': anio - i}
            );
        }
    },
    methods:{
        setCambio(){
            this.$emit('cambioAnio');
        }

    }
}
</script>

<style scoped>

</style>
