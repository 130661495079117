import { render, staticRenderFns } from "./ventas.vue?vue&type=template&id=33d2fc17&scoped=true&"
import script from "./ventas.vue?vue&type=script&lang=js&"
export * from "./ventas.vue?vue&type=script&lang=js&"
import style0 from "./ventas.vue?vue&type=style&index=0&id=33d2fc17&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d2fc17",
  null
  
)

export default component.exports