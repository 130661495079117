<template>
    <div>
        <div class="example">
            <div class="border">
                <div class="bg-gray-300 nav-bg">
                    <nav class="nav nav-tabs">
                        <a class="nav-link active" data-toggle="tab" href="#tabCont1" ref="general">Sucursales <i class="fas fa-store-alt"></i></a>
                        <a class="nav-link" data-toggle="tab" href="#tabCont2" ref="infor">Distribución <i class="fas fa-map-marked-alt"></i></a>
                    </nav>
                </div>
                <div class="card-body tab-content">
                    <div class="tab-pane active show" id="tabCont1">
                        <div class="text-center">
                            <sucursales-admin></sucursales-admin>
                        </div>
                    </div>
                    <div class="tab-pane" id="tabCont2">
                        <div class="text-center">
                            <sucursales-distribucion></sucursales-distribucion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "contenedor"
}
</script>

<style scoped>

</style>
