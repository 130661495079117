<template>
    <div>
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <button class="btn ripple btn-success btn-with-icon text-center" @click="newProducto" v-if="!showAlta">
                    <i class="fas fa-plus"></i> Nuevo Productos</button>

            </div>
            <div class="col-md-4"></div>
        </div><br>
        <div class="row" v-if="showAlta">
            <div class="col-md-4"></div>
            <div class="col-md-2">
                <button class="btn ripple btn-danger btn-with-icon text-center" @click="cancelProducto" v-if="showAlta">
                    <i class="fas fa-times"></i> Cancelar</button>
            </div>
            <div class="col-md-2">
                <button class="btn ripple btn-success btn-with-icon text-center" @click="valProducto" v-if="showAlta">
                    <i class="fas fa-save"></i> Registrar</button>
            </div>
            <div class="col-md-4"></div>
        </div><br>
        <div class="row" v-if="showAlta">
            <div class="col-md-4">
                <label><b>Nombre Producto:</b></label>
                <input type="text" placeholder="Ingrese nombre" maxlength="120" class="form-control" v-model="newPto.nombre">
            </div>
            <div class="col-md-4">
                <label><b>Imagen: <small><b class="text-danger">Para cambiar imagen, seleccione otro archivo</b></small></b></label>
                <input type="file" name="myImage" accept="image/*"  class="form-control" @change="handleImage"/>
                <br>
                <div v-show="newPto.imagen !==''">
                    <i class="fa fa-eye" style="color: darkred;cursor:pointer" @click="imageProducto = !imageProducto"> Ocultar imagen</i>
                    <img :src="newPto.imagen" class="img img-responsive" alt="ImagenProducto" v-show="imageProducto"/>
                </div>
            </div>
            <div class="col-md-2">
                <label><b>Precio:</b></label>
                <input type="number" placeholder="Ingrese Precio" min="0" max="1000" v-model="newPto.precio" class="form-control" @keypress="isNumber($event)">
            </div>
            <div class="col-md-2">
                <label><b>Gramaje:</b></label>
                <input type="number" placeholder="Ingrese Gramaje" min="0" max="10000" v-model="newPto.gramaje" class="form-control" @keypress="isNumber($event)">
            </div>
        </div>
        <div class="row" v-if="showLoad">
            <div class="col-md-12 text-center">
                <br>
                <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                <p><b>Realizando acción, por favor espere!</b></p>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-12">
                <v-client-table :data="tableData" :columns="columns" :options="options" ref="UsersTable" v-show="!showLoad">
                    <div slot="Pathimg" slot-scope="props">
                        <img alt="Imagen producto" height="80px" :src="props.row.Pathimg">
                    </div>
                    <div slot="Stt" slot-scope="props">
                        <span v-if="props.row.Stt" class="badge badge-success ripple" @click="ChangeStatus(props.row.Id,false)">Activo</span>
                        <span v-else class="badge badge-danger ripple" @click="ChangeStatus(props.row.Id,true)">Inactivo</span>
                    </div>
                </v-client-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "productos",
    data(){
        return{
            showAlta:false,
            showLoad:false,
            imageProducto:true,
            newPto:{
                nombre:'',
                precio:'',
                imagen:'',
                gramaje:'',
            },
            columns: ['Id', 'Nombre','Precio','Gramaje','CreatedAt','Stt','Pathimg'],
            tableData: [],
            options: {
                // see the options API
                footerHeadings:false,
                filterByColumn:true,
                perPage:10,
                perPageValues: [10,15,25,45,50],
                filterable: ['Nombre','Precio','Gramaje','Stt'],
                sortable: ['Nombre','Precio','Gramaje','CreatedAt','Stt'],
                listColumns: {Stt: [
                        {'id': true,
                         'text':'Activo'},
                        {'id': false,
                            'text':'Inactivo'}
                    ]},
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                headings: {
                    Id: '#',
                    Nombre: 'Producto',
                    Stt: 'Estatus',
                },
                columnsClasses: {
                    Nombre: 'text-justify',
                    Stt: 'text-center',
                    Pathimg: 'text-center',
                    Precio: 'text-center tx-bold txt-info',
                    Gramaje: 'text-center tx-bold txt-success',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Recopilando información, por favor espere...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods:{
        ChangeStatus(clave,type){let $this = this;
            var id = this.getIdx(clave);
            this.$swal.fire({
                title: !type ? '¿Desactivar Producto?' : '¿Activar Producto?',
                text: !type ? 'Al aceptar, el producto, ya no estará disponible para la venta' : 'Al aceptar, el producto, estará disponible para la venta',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: !type ? 'Desactivar' : 'Activar',
                cancelButtonText: 'Cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/api/Productos/changeEstatus',{clave: clave, stt: type})
                        .then(r => {
                                if (r.data.Success){
                                    $this.$swal.fire(
                                        'Exito!',
                                        'Acción realizada satisfactoriamente.',
                                        'success'
                                    );
                                    $this.tableData[id]['Stt'] = type;
                                }else{
                                    $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                }
                            },
                            error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                        );
                }
            });
        },
        getIdx(clave){let $this = this;
            var idx = 0;
            for (var i = 0; i< this.tableData.length; i++){
                if (clave === $this.tableData[i]['Id']){
                    idx = i;
                }
            }
            return idx;
        },
        newProducto(){
            this.showAlta = true;
        },
        cancelProducto(){
            this.showAlta = false;
            this.newPto.nombre='';
            this.newPto.imagen='';
            this.newPto.precio='';
            this.newPto.gramaje='';
        },
        valProducto(){
            this.showLoad = true;
            if (this.newPto.nombre === ''){
                this.$toastMessage('error','Ingresa un nombre',4000,'center');
                this.showLoad = false;
            }
            else if (this.newPto.nombre.length < 6){
                this.$toastMessage('error','Ingresa un nombre con más de 6 caracteres',4000,'center');
                this.showLoad = false;
            }
            else if (this.newPto.imagen === ''){
                this.$toastMessage('error','Seleccione una imagen por favor',4000,'center');
                this.showLoad = false;
            }
            else if (this.newPto.precio === ''){
                this.$toastMessage('error','Ingresa un precio',4000,'center');
                this.showLoad = false;
            }
            else if (this.newPto.gramaje === ''){
                this.$toastMessage('error','Ingresa un gramaje',4000,'center');
                this.showLoad = false;
            }
            else{ let $this = this;
                axios.post('/api/Productos/valProducto',{pto:this.newPto.nombre})
                    .then(r => {
                            if (r.data.Success){
                                $this.saveProducto();
                            }
                            else{
                                $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                            }
                        },
                        error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                    );

            }

        },
        saveProducto(){let $this = this;
            axios.post('/api/Productos/saveProducto',{pto:this.newPto})
                .then(r => {
                        if (r.data.Success){
                            $this.$toastMessage('success',r.data.Mensaje,4000,'center');
                            this.cancelProducto();
                        }
                        this.showLoad = false;
                        this.getData();
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        handleImage(e){let $this = this;
            const selectedImage = e.target.files[0];
            var reader = new FileReader();
            reader.onload = function(event) {
                $this.newPto.imagen = event.target.result;
            };
            reader.onerror = function(error) {
                alert(error);
            };
            reader.readAsDataURL(selectedImage);
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        getData(){ let $this = this;
            this.showLoad=true;
            $this.tableData = [];
            axios.post('/api/Productos/getData')
                .then(r => {
                        if (r.data.Success){
                            $this.tableData = r.data.Data;
                        }
                        $this.showLoad=false;
                        $this.$toastMessage('success','Información recolectada correctamente',3000,'top-right');
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );

        }
    }
}
</script>

<style scoped>

</style>
