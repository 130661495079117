<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <button v-if="typeUsers===1" type="button" class="btn ripple btn-outline-danger" @click="getData(0)" ><i class="fe fe-eye"></i> Inactivos</button>
                <button v-if="typeUsers===0" type="button" class="btn ripple btn-outline-success" @click="getData(1)" ><i class="fe fe-eye"></i> Activos</button>
            </div>
            <div class="col-md-3">
                <button type="button" class="modal-effect btn ripple btn-outline-info"  data-effect="effect-super-scaled" data-toggle="modal" href="#modalnewUser"><i class="fe fe-user-plus"></i> Nuevo Usuario</button>
                <button style="display:none" type="button" class="modal-effect btn ripple btn-outline-info"  data-effect="effect-super-scaled" data-toggle="modal" href="#modalEditUser"><i class="fe fe-user-x" ref="buttonModalEdit"></i> Editar Usuario</button>
            </div>
            <div class="col-md-3">
            </div>
            <div class="col-md-3">
                <button type="button" class="btn ripple btn-outline-success" @click="ExportFile"><i class="far fa-file-excel"></i> Exportar</button>
                <div class="row" v-if="showLoadDownload">
                    <div class="col-md-12 text-center"><br><br>
                        <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                        <p><b>Descargando información!</b></p>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <v-client-table :data="tableData" :columns="columns" :options="options" ref="UsersTable" v-show="!showLoad">
            <div slot="Stt" slot-scope="props">
                <span v-if="props.row.Stt === 'Activo'" class="badge badge-success ripple" @click="ChangeStatus(props.row.Cveentusr,0)">Activo</span>
                <span v-else class="badge badge-danger ripple" @click="ChangeStatus(props.row.Cveentusr,1)">Inactivo</span>
            </div>
            <div slot="Accion" slot-scope="props">
                <i class="fa fa-lock ripple" style="cursor:pointer;color: #fad208" data-placement="top" data-toggle="tooltip-primary" title="Cambiar Contraseña" data-original-title="Cambiar Contraseña" @click="changePass(props.row.Cveentusr,props.row.Nombre)"></i>
                <i class="fa fa-user-edit ripple" style="cursor:pointer;color: #0264f8" data-placement="top" data-toggle="tooltip-primary" title="Editar Usuario" data-original-title="Editar Usuario" @click="editUser(props.row.Cveentusr)"></i>
            </div>
            <div slot="Fchcrt" slot-scope="props">
                <small>{{props.row.Fchcrt}}</small>
            </div>
            <div slot="FU" slot-scope="props">
                <small>{{props.row.FU}}</small>
            </div>
            <div slot="Ciudad" slot-scope="props">
                <small>{{props.row.Ciudad}}</small>
            </div>
            <div slot="Nombre" slot-scope="props">
                <small>{{props.row.Nombre}}</small>
            </div>
            <div slot="Rol" slot-scope="props">
                <small>{{props.row.Rol}}</small><br>
            </div>
            <div slot="Tienda" slot-scope="props">
                <small>{{props.row.Tienda}}</small><br>
                <i class="fas fa-edit" v-show="props.row.Tienda !== '-' && !props.row.Asignar" style="color: #0c025b;cursor: pointer" @click="InitAsignar(props.row.Cveentusr)"></i>
                <select-tienda v-show="props.row.Asignar" :placeholderm="'Tienda'" :ref="'tienda'+props.row.Cveentusr"></select-tienda>
                <i class="fas fa-save" v-show="props.row.Asignar" style="color: #4b8e1e;cursor: pointer" @click="saveAsignar(props.row.Cveentusr)"></i> &nbsp;
                <i class="fas fa-times" v-show="props.row.Asignar" style="color: #bb1834;cursor: pointer" @click="cancelAsignar(props.row.Cveentusr)"></i>
            </div>
        </v-client-table>
        <div class="row" v-if="showLoad">
            <div class="col-md-12 text-center"><br><br>
                <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                <p><b>Buscando información por favor espere!</b></p>
            </div>
        </div>
        <modalnewuser-component ref="newUserModal" v-on:finalizaRegistro="getData(typeUsers)"></modalnewuser-component>
        <modaleditser-component ref="editUserModal" v-on:editFinish="endEdit()"></modaleditser-component>
    </div>
</template>

<script>
export default {
name: "tableUsers",
    data(){
        return{
            tipoUserEdit: {},
            rolUserEdit: {},
            typeUsers:1,
            msg:"asdf",
            showLoad:true,
            showLoadDownload:false,
            columns: ['Cveentusr', 'Nombre','Numempleado','Telefono','Stt','Ciudad','Tienda','Rol','Fchcrt','FU','Accion'],
            tableData: [],
            optionsTipo: [],
            optionsRol: [],
            options: {
                // see the options API
                footerHeadings:false,
                filterByColumn:true,
                perPage:10,
                perPageValues: [10,15,25,45,50],
                filterable: ['Nombre','Ciudad','Rol'],
                sortable: ['Nombre','Ciudad','Rol','FU','Fchcrt'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {Agencia: [], Tipo: [], Rol: [],},
                headings: {
                    Cveentusr: '#',
                    Numempleado: '# Empleado',
                    Nombre: 'Nombre Empleado',
                    Agencia: 'Agencia',
                    Stt: 'Estatus',
                    Verapp: 'Ver App',
                    FU: 'Fecha Update App',
                    Fchcrt: 'Fecha Alta',
                    Rol: 'Rol',
                    Accion: 'Acciones',
                    Usrentusr: 'Usuario',
                },
                columnsClasses: {
                    Idemp: 'text-justify',
                    Stt: 'text-center',
                    Tipo: 'text-center',
                    Ciudad: 'text-center',
                    Agencia: 'text-center',
                    Verapp: 'text-center',
                    Tienda: 'text-center',
                    FU: 'text-center',
                    Fchcrt: 'text-center',
                    Rol: 'text-center',
                    Accion: 'text-center',
                    Usrentusr: 'text-justify',
                    Serial: 'text-justify',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Recopilando información, por favor espere...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            }
        }
    },
    mounted() {
        this.getData(this.typeUsers);
    },
    methods:{
        saveAsignar(clave){ let $this = this;
            if ( this.$refs['tienda'+clave].selectedTienda === null){
                $this.$toastMessage('error','Error: Selecciona una sucursal, para asignarla al Cajero!',4000,'center');
            }else{
                var id = this.getIdx(clave);

                axios.post('/api/Users/asignSucursal',{usuario: clave, tienda: $this.$refs['tienda'+clave].selectedTienda.value })
                    .then(r => {
                            if (r.data.Success){
                                $this.tableData[id].Asignar = false;
                                $this.$toastMessage('success',r.data.Mensaje,6000,'top-right')
                                $this.getData($this.typeUsers);
                            }else{
                                $this.$toastMessage('error',r.data.Mensaje,6000,'center')
                            }
                        },
                        error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                    );
            }

        },
        cancelAsignar(clave){
            var id = this.getIdx(clave);
            this.tableData[id].Asignar = false;
        },
        InitAsignar(clave){
            var id = this.getIdx(clave);
            this.tableData[id].Asignar = true;
            this.$refs['tienda'+clave].getSucursales();
        },
        saveTipo(clave){let $this = this;
            var indexSelected = this.$refs['opcionTipo'+clave].selectedIndex;
            var id = this.getIdx(clave);
            axios.post('/api/Users/changeTipo',{cveuser: clave, tipo: this.$refs['opcionTipo'+clave].value })
                .then(r => {
                        if (r.data.Success){
                            $this.tableData[id].Tipo = this.$refs['opcionTipo'+clave].options[indexSelected].text;
                            $this.tableData[id].Edit = false;
                            $this.$toastMessage('success',r.data.Mensaje,6000,'top-right')
                        }else{
                            $this.$toastMessage('error',r.data.Mensaje,6000,'center')
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        startEditTipo(clave){
            var id = this.getIdx(clave);
            this.optionsTipo=[];
            this.optionsTipo = this.$refs.newUserModal.optionsTipo;
            this.tableData[id].Edit = !this.tableData[id].Edit;
        },
        cancelEditTipo(clave){
            var id = this.getIdx(clave);
            this.tipoUserEdit={};
            this.tableData[id].Edit = false;
        },
        saveRol(clave){let $this = this;
            var indexSelected = this.$refs['opcionRol'+clave].selectedIndex;
            var id = this.getIdx(clave);
            axios.post('/api/Users/changeRol',{cveuser: clave, rol: this.$refs['opcionRol'+clave].value })
                .then(r => {
                        if (r.data.Success){
                            $this.tableData[id].Rol = this.$refs['opcionRol'+clave].options[indexSelected].text;
                            $this.tableData[id].EditRol = false;
                            $this.$toastMessage('success',r.data.Mensaje,6000,'top-right')
                        }else{
                            $this.$toastMessage('error',r.data.Mensaje,6000,'center')
                        }
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        startEditRol(clave){
            var id = this.getIdx(clave);
            this.tableData[id].EditRol = !this.tableData[id].EditRol;
        },
        cancelEditRol(clave){
            var id = this.getIdx(clave);
            this.rolUserEdit={};
            this.tableData[id].EditRol = false;
        },
         getData(type){let $this = this;
            this.typeUsers = type;this.showLoad=true;
             axios.post('/api/Users/getUsuarios',{typeUsers:type})
                 .then(r => {
                     if (r.data.Success){
                         $this.tableData = [];
                         $this.tableData = r.data.Data;
                         $this.showLoad=false;
                         $this.options.listColumns.Agencia = r.data.Agencias;
                         $this.options.listColumns.Tipo = r.data.Tipos;
                         $this.options.listColumns.Rol = r.data.Roles;
                         $this.optionsRol = r.data.Roles;
                     }else{
                         $this.tableData = [];
                         $this.tableData = r.data.Data;
                         $this.showLoad=false;
                         $this.options.listColumns.Agencia = r.data.Agencias;
                         $this.options.listColumns.Tipo = r.data.Tipos;
                         $this.options.listColumns.Rol = r.data.Roles;
                         $this.$toastMessage('error',r.data.Mensaje,6000,'center')
                     }
                 },
                 error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
             );
        },
        getIdx(clave){let $this = this;
             var idx = 0;
             for (var i = 0; i< this.tableData.length; i++){
                 if (clave === $this.tableData[i]['Cveentusr']){
                     idx = i;
                 }
             }
             return idx;
        },
        DeleteAllSeriales(){let $this = this;
            this.$swal.fire({
                title: '¿Eliminar Todos Los Seriales?',
                text: "Esto solo se realiza en una actualización",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, eliminar!',
                cancelButtonText: 'No, cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/api/Users/deleteAllSeriales')
                        .then(r => {
                                if (r.data.Success){
                                    $this.$swal.fire(
                                        'Eliminados!',
                                        'Ahora pueden ingresar con otro dispositivo.',
                                        'success'
                                    );
                                    $this.getData($this.typeUsers);
                                }else{
                                    $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                }
                            },
                            error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                        );
                }
            });
        },
        changePass(clave,name){let $this = this;
             var newPass = '';
            this.$swal.fire({
                title: 'Cambio de contraseña: ' + name,
                icon: 'warning',
                input: 'text',
                inputLabel: 'Nueva Contraseña',
                inputValue: newPass,
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'La contraseña no puede ser en blanco!'
                    }else if(value.length <=7){
                        return 'La contraseña no puede ser en menor a 7 caracteres!'
                    }else{
                        axios.post('/api/Users/changePassword',{clave: clave, pass: value})
                            .then(r => {
                                    if (r.data.Success){
                                        $this.$swal.fire(
                                            'Exito!',
                                            'Contraseña Actualizada.',
                                            'success'
                                        );
                                    }else{
                                        $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                    }
                                },
                                error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                            );
                    }
                }
            });
        },
        ChangeStatus(clave,type){let $this = this;
            var id = this.getIdx(clave);
            this.$swal.fire({
                title: type === 0 ? '¿Desactivar Usuario?' : '¿Activar Usuario?',
                text: type === 0 ? 'Al aceptar, se le negará el acceso al usuario' : 'Al aceptar, se le permitirá el acceso al usuario',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: type === 0 ? 'Desactivar' : 'Activar',
                cancelButtonText: 'Cancelar!',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/api/Users/changeStatus',{clave: clave, stt: type})
                        .then(r => {
                                if (r.data.Success){
                                    $this.$swal.fire(
                                        'Exito!',
                                        'Acción realizada satisfactoriamente.',
                                        'success'
                                    );
                                    $this.tableData[id]['Stt'] = type === 0 ? 'Inactivo' : 'Activo';
                                }else{
                                    $this.$toastMessage('error',r.data.Mensaje,6000,'center');
                                }
                            },
                            error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                        );
                }
            });
        },
        ExportFile(){let $this = this;
            this.showLoadDownload = true;
            axios({
                method: 'post',
                url: '/api/Users/exportUsersToExcel',
                responseType: 'arraybuffer',
                data: {typeUsers: this.typeUsers}}).then(r => {
                        let blob = new Blob([r.data], { type: 'application/xlsx' })
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = 'UsuariosAtenea.xlsx'
                        link.click()
                        $this.showLoadDownload=false;
                    },
                    error => {$this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');}
                );
        },
        editUser(claveUser){
             var id = this.getIdx(claveUser);
             this.$refs.editUserModal.setData(this.tableData[id]);
             this.$refs.buttonModalEdit.click();
        },
        endEdit(){
             //console.log(this.$refs.editUserModal.dataEditUser);
             this.getData(this.typeUsers);

        }

    }
}
</script>

<style scoped>
tbody {
    display:block;
    height:400px;
    overflow:auto;
}

thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;/* even columns width , fix width of table too*/
}

thead {
    width: calc( 100% )/* scrollbar is average 1em/16px width, remove it from thead width */
}
</style>
