<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card custom-card">
                    <div class="card-body">
                        <transition name="fade">
                            <form-wizard v-if="showForm" @on-complete="onComplete"
                                         shape="tab"
                                         subtitle=""
                                         error-color="#b90c17"
                                         title=""
                                         back-button-text="Anterior"
                                         next-button-text="Siguiente"
                                         finish-button-text="Registrarme"
                                         @on-loading="setLoading"
                                         @on-validate="handleValidation"
                                         @on-error="handleErrorMessage"
                                         color="#4a2c58" step-size="sm">
                                <tab-content title="Bienvenida"
                                             icon="la la-flag" :before-change="validateTerminos">
                                    <div class="row">
                                        <div class="col"></div>
                                        <div class="col-5">
                                            <img alt="Image" src="/theme/img/brand/logo.png" class="img-fluid">
                                        </div>
                                        <div class="col"></div>
                                    </div>
                                    <div class="text-center">
                                        <p class="text-center h5">
                                            ¡Bienvenido a <b style="color: #4a2c58">Yogocup</b>! <br>
                                        </p>
                                        <p class="text-center">
                                            Aquí encontrarás exclusivas sorpresas y promociones.
                                        </p>
                                        <p class="text-center tx-bold h4" style="color: #4a2c58">
                                            ¡Regístrate!
                                        </p>
                                        <a class="btn ripple btn-primary" data-target="#scrollmodal" data-toggle="modal" href="">Ver términos y condiciones</a>
                                        <br>
                                        <br>
                                        <div class="text-center">
                                            <div class="pretty p-icon p-jelly" style="font-size:16px">
                                                <input type="checkbox" v-model="accept"/>
                                                <div class="state p-success-o">
                                                    <i class="icon fas fa-check-square"></i>
                                                    <label>Acepto Términos y Condiciones</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tab-content>
                                <tab-content title="Tus Datos" :before-change="validateAsync"
                                             icon="la la-user" >
                                    <div class="row">
                                        <div class="col-12">
                                            <label><b>Email: <i class="la la-envelope-o"></i></b></label>
                                            <input type="email" class="form-control" placeholder="Ingrese su email" maxlength="60" v-model="dataCustomer.email"
                                                   :class="{ 'is-invalid state-invalid': $v.dataCustomer.email.$invalid,'is-valid state-valid': !$v.dataCustomer.email.$invalid }">
                                            <div class="invalid-feedback" v-if="$v.dataCustomer.email.$invalid">Ingresa un email valido</div>
                                            <label><b>Apodo: <i class="la la-user-secret"></i></b></label>
                                            <input type="text" class="form-control" placeholder="Ingrese su apodo" maxlength="60" v-model="dataCustomer.apodo"
                                                   :class="{ 'is-invalid state-invalid': $v.dataCustomer.apodo.$invalid ,'is-valid state-valid': !$v.dataCustomer.apodo.$invalid}">
                                            <div class="invalid-feedback">{{!$v.dataCustomer.apodo.minLength ? 'Longitud minima ' + $v.dataCustomer.apodo.$params.minLength.min : 'Apodo obligatorio'}}</div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <label><b>teléfono: <i class="la la-mobile-phone"></i></b></label>
                                                    <input type="tel" class="form-control" placeholder="Ingrese su número" maxlength="10" v-model="dataCustomer.tel"
                                                           oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"><br>
                                                </div>
                                                <div class="col-md-6"></div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="text-center">
                                                        <label><b>Cumple años: <i class="la la-birthday-cake"></i></b></label>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <label><b>Año:</b></label>
                                                    <select-anio :placeholderm="'Año'" v-on:cambioAnio="setAnio" ref="anioSelected"></select-anio>
                                                </div>
                                                <div class="col-md-4">
                                                    <label><b>Mes:</b></label>
                                                    <select-mes :placeholderm="'Mes'" v-on:cambioMes="setMes" ref="mesSelected"></select-mes>
                                                </div>
                                                <div class="col-md-4">
                                                    <label><b>Día:</b></label>
                                                    <select-dia :placeholderm="'Dia'" v-on:cambioDia="setDia" ref="diaSelected"></select-dia>
                                                </div>
                                            </div>
                                        </div>
                                    </div><br>
                                </tab-content>
                                <tab-content title="Valida Tus Datos"
                                             icon="la la-eye">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col">
                                                    <label><b>Email: <i class="la la-envelope-o"></i></b></label><br>
                                                    <h3 style="color: #4a2c58"><b>{{dataCustomer.email}}</b></h3>
                                                </div>
                                                <div class="col">
                                                    <label><b>Apodo: <i class="la la-user-secret"></i></b></label><br>
                                                    <h3 style="color: #4a2c58"><b>{{dataCustomer.apodo}}</b></h3>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col">
                                                    <label><b># de contacto: <i class="la la-mobile-phone"></i></b></label><br>
                                                    <h3 style="color: #4a2c58"><b>{{dataCustomer.tel}}</b></h3>
                                                </div>
                                                <div class="col">
                                                    <label><b>Fecha de cumpleaños (Año-mes-día): <i class="la la-birthday-cake"></i></b></label><br>
                                                    <h3 style="color: #4a2c58" v-if="dataCustomer.dia !== null && dataCustomer.mes !== null && dataCustomer.anio !== null"><b>{{dataCustomer.anio.value}}-{{dataCustomer.mes.value}}-{{dataCustomer.dia.value}}</b></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tab-content>
                                <div v-if="loadingWizard">
                                    <div class="text-center">
                                        <img alt="Loading" src="/theme/img/loads/loadfile.gif" width="60px"><br>
                                        <span style="color: rgba(110,89,150,0.61)"><b>Validando, por favor espere...</b></span>
                                    </div>
                                </div>
                                <div v-if="errorMsg">
                                    <div class="text-center">
                                        <span style="color: #ff473d"><b>{{errorMsg}}</b></span>
                                    </div>
                                </div>
                            </form-wizard>

                        </transition>
                        <transition name="fade">
                            <div v-if="!showForm" class="text-center">
                                <div class="text-center" v-show="!endProcess">
                                    <div class="row">
                                        <div class="col"></div>
                                        <div class="col-5">
                                            <img alt="Image" src="/theme/img/brand/logo.png" class="img-fluid">
                                        </div>
                                        <div class="col"></div>
                                    </div>
                                    <div class="text-center">
                                        <p class="text-center">
                                            Gracias por tu <b style="color: #8bc541">Registro</b> en <b style="color: #4a2c58">Yogocup</b> te esperan grandes sorpresas y promociones.
                                        </p>
                                    </div>
                                    <img alt="LoadingRegister" src="/theme/img/loads/loadfile.gif" width="60px" v-if="loadingRegister"><br>
                                    <b style="color: #4a2c58" v-if="msgUno">Enviando información, por favor espere...</b>
                                    <b style="color: #4a2c58" v-if="msgDos">Generando QR, por favor espere...</b>
                                    <b style="color: #4a2c58" v-if="msgTres">Preparando tus sorpresas, por favor espere...</b>
                                </div>
                                <div class="row" v-show="endProcess">
                                    <div class="col"></div>
                                    <div class="col-5">
                                        <img alt="Image" src="/theme/img/brand/logo.png" class="img-fluid">
                                    </div>
                                    <div class="col"></div>
                                </div>
                                <p class="text-justify" v-show="endProcess">
                                    GRACIAS: <b style="color: #4a2c58">{{dataCustomer.apodo}}</b><br>
                                    MUESTRA TÚ CÓDIGO QR AL REALIZAR TÚ COMPRA CON NOSOTROS Y PODRÁS RECIBIR UN CUPÓN DE DESCUENTO.
                                </p>
                                <img ref="image" alt="qrUser" class="img-fluid" :src="qrUsr" v-show="endProcess">
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <!-- Scroll with content modal -->
        <div class="modal" id="scrollmodal">
            <div class="modal-dialog modal-dialog-scrollable" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title"><b>AVISO DE PRIVACIDAD INTEGRAL</b></h6><button aria-label="Close" class="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <p class="text-justify">
                            <b>I.-</b> RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES<br>
                            Yogocup y/o Alfonso Orozco Vizcaino  Con domicilio Av. Providencia 2951-A Col. Providencia 4ª Secc, Guadalajara Jalisco teléfono 3336112007 correo electrónico promociones@yogocup.com es responsable de sus datos personales conforme a este aviso de privacidad, en cumplimiento a lo ordenado en la LFPDPPP y su Reglamento, así como los lineamientos publicados en el DOF.<br>En la recopilación y tratamiento de dicha información Yogocup y/o Alfonso Orozco Vizcaino se compromete y obliga observar y cumplir los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad.
                            <br>
                            <b>II.-</b> DATOS PERSONALES<br>
                            Por lo tanto y para alcanzar las finalidades antes expuestas, se tratarán los siguientes datos personales:
                            Datos de contacto del titular (Nombre o apodo, fecha de nacimiento, teléfono, correo electrónico)
<br>
                            En algunos servicios, podrá tomar fotografías o videos, los cuales pueden ser necesarios para completar la prestación del servicio, esto con la finalidad de llevar un registro, el uso de la imagen se autorizara por medio del presente Aviso, haciendo uso el Responsable conforme al mismo, por medio de televisión, redes sociales, página de internet, medios impresos y electrónicos.
                            <br>
                                <b>III.-</b> LOS DATOS PERSONALES QUE RECABAMOS TIENEN COMO FINALIDAD.<br>
                            Los datos personales que los titulares proporcionen al momento de su registro al sistema tienen como finalidad primaria, el registro y participación en el sistema de cliente frecuente del responsable.<br>
                            A) Finalidades Necesarias para la relación con el titular de los datos.<br>
                            1.- Finalidades que dan origen y son necesarias para la existencia, mantenimiento y cumplimiento de la relación jurídica entre el responsable y titular.<br>
                            2.- Envío de comprobante de cupones y transacciones registradas en su usuario.<br>
                            3.- Conocimiento de inscripción, y;<br>
                            4.- Envío de notificaciones.<br>
                            <br>
                            B) Otras Finalidades<br>
                            1.- Mejorar la calidad del servicio realizando evaluaciones, estudios, estadísticas periódicas.<br>
                            2.- Informarle sobre promoción y mercadeo de nuevos productos y servicios de Yogocup.<br>
                            <br>
                            En caso de que los titulares no deseen que YOGOCUP de tratamiento a sus datos personales para las finalidades previstas en el inciso B) anterior, podrá manifestar su negativa dirigiendo una solicitud de “ARCO” enviando un correo electrónico a la siguiente dirección promociones@yogocup.com
                            <br>
                                    <b>IV.-</b> OPCIONES Y MEDIOS PARA LA LIMITACION DE USO Y DIVULGACION DE DATOS PERSONALES<br>
                            Los titulares podrán limitar el uso de sus datos personales, a través de un correo electrónico a promociones@yogocup.com o notificación por escrito Av. Providencia 2951-A Col. Providencia 4ª Secc, Guadalajara, Jal 44639 al departamento de datos personales de Lunes a Viernes de 11:00 am a 7:00 pm en la que se señale la limitación al uso de los datos deseados.
                            <br>
                                        <b>V.-</b> SOLICITUD DE ACCESO, RECTIFICACION, CANCELACION U OPOSICION DE DATOS PERSONALES Y REVOCACION DEL CONSENTIMIENTO (SOLICITUD ARCO).<br>
                            Todos los datos personales son tratados de conformidad con la legislación aplicable y vigente, por ello los titulares de datos personales tienen en todo momento el derecho de acceder a los datos personales que posee YOGOCUP y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o incompletos; cancelarlos cuando resulten ser excesivos o innecesarios para las finalidades que justificaron su obtención; y oponerse a su tratamiento.
                            <br>
                                            <b>VI.-</b> MEDIOS PARA EJERCER DERECHOS ARCO Y REVOCACION DEL CONSENTIMIENTO<br>
                            Para hacer una solicitud ARCO, el titular de los datos personales deberá presentar una solicitud por escrito al correo promociones@yogocup.com o notificarlo por escrito al domicilio previamente mencionado de YOGOCUP de Lunes a Viernes de 11:00 am a 7:00 y deberá de contener la siguiente información:<br>
                            <i class="fas fa-globe-americas"></i>	Nombre del Titular (Nombre, Razón social o denominación Social)<br>
                            <i class="fas fa-globe-americas"></i>	Domicilio del Titular y dirección de correo electrónico para comunicar respuesta a solicitud.<br>
                            <i class="fas fa-globe-americas"></i>	Documentos que acrediten identidad o autorización para representarlo en la solicitud, (credencial del<br>
                            INE, pasaporte vigente, cédula profesional o documento migratorio).<br>
                            <i class="fas fa-globe-americas"></i>	Descripción de datos personales sobre lo que se pretende ejercer algún derecho ARCO.<br>
                            <i class="fas fa-globe-americas"></i>	Cualquier otro elemento que permita la localización de los datos personales y atención a la solicitud.<br>
                            <i class="fas fa-globe-americas"></i>	Poder en caso de ser apoderado legal.<br>
                            El responsable de los datos responderá la solicitud mediante el medio que el usuario haya elegido, en un término de 20 (veinte) días hábiles. En caso de que la Solicitud ARCO se conteste de manera afirmativa o procedente, los cambios solicitados se harán en un plazo máximo de 15 días hábiles. El Responsable podrá notificarle dentro de los plazos referidos en este párrafo la prórroga de los mismos, por una sola vez, por un periodo igual al original.<br>

                            YOGOCUP podrá negar el ejercicio de los Derechos ARCO, en los siguientes supuestos:<br>
                            I. Cuando el solicitante no sea el titular de los datos personales, o no pueda acreditar la representación del titular;<br>
                            II. Cuando sus datos personales no obren en la base de datos de YOGOCUP;<br>
                            III. Cuando se lesionen los derechos de un tercero;<br>
                            IV. Cuando exista un impedimento legal o la resolución de una autoridad competente, que restrinja sus Derechos ARCO;<br>
                            VI. Cuando la rectificación, cancelación u oposición haya sido previamente realizada.<br>
                            Sin embargo es importante recalcar que no en todos los casos podremos atender su solicitud ARCO, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. El Titular deberá considerar que para ciertos fines, la revocación de su consentimiento implicara la suspensión del servicio o la conclusión con su relación con YOGOCUP.<br>
                            <br>
                                                <b>VII.-</b> REVOCACIÓN DE CONSENTIMIENTO<br>
                            El titular podrá en cualquier momento solicitar la revocación del consentimiento otorgado a YOGOCUP para tratar sus datos personales enviando una solicitud a “ARCO” por escrito a través de un correo electrónico a promociones@yogocup.com o notificación por escrito a  Av. Providencia 2951-A Col. Providencia 4ª Secc, Guadalajara, Jal 44639, al departamento de datos personales de Lunes a Viernes de 11:00 am a 7:00pm, en la que se detalle claramente los datos respecto de los que revoca su consentimiento.
                            <br>
                                                    <b>VIII.-</b> INFORMACIÓN OBTENIDA POR MEDIOS REMOTOS O LOCALES DE COMUNICACIÓN ELECTRONICA U ÓPTIMA<br>
                            Yogocup recaba y almacena información a través de ingreso  a su sitio web por medio de la sección Formulario de contacto y la Sección Noticias; forma de contacto: nombre completo, correo electrónico, mensaje, y el segundo suscripción de correo electrónico, los cuales serán enviados a la dirección de correo electrónico de la persona encargada de recibir dicha solicitud. Dicha información se obtiene y almacena con el fin de medir la actividad del sito e identificar tendencias sobre navegación que no son atribuibles a un individuo en específico, así como para las actividades de YOGOCUP.
                            <br>
                                                        <b>IX.-</b> NOTIFICACIÓN DE CAMBIO DE AVISO DE PRIVACIDAD<br>
                            El presente aviso de privacidad podrá ser modificado en cualquier momento para cumplir con actualizaciones legislativas, jurisprudenciales, políticas, internas, nuevos requisitos para la prestación de servicios de Yogocup, lo cual darán a conocer en forma personal o bien, por medio de publicación de un aviso en lugar visible y/o en la pagina de internet www.yogocup.com


                        </p>
                        <p class="small text-justify">
                            Si usted considera que su derecho de protección de datos personales ha sido lesionado por alguna de nuestras actuaciones o respuestas, presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podrá interponer la queja o denuncia correspondiente ante el IFAI, para mayor información visite www.ifai.org.mx.
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button class="btn ripple btn-success" data-dismiss="modal" type="button">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {VueAvatar} from 'vue-avatar-editor-improved';
import { required, sameAs, minLength, email } from 'vuelidate/lib/validators';
import birthDatepicker from 'vue-birth-datepicker/src/birth-datepicker';
export default {
    name: "registro",
    components: {
        VueAvatar,
        birthDatepicker
    },
    data(){
        return{
            dataCustomer:{
                birthday:null,
                email:'',
                apodo:'',
                tel:'',
                avatar:'',
                anio:null,
                mes:null,
                dia:null,
            },
            imgUsr:'/storage/qr/logo.png',
            qrUsr:'/storage/qr/logo.png',
            loadingWizard: false,
            loadingRegister: false,
            msgUno: false,
            msgDos: false,
            msgTres: false,
            errorMsg: null,
            showForm:true,
            rotation: 0,
            scale: 1,
            accept:false,
            endProcess:false,
        }
    },
    validations: {
        dataCustomer: {
            anio: {required},
            mes: {required},
            dia: {required},
            email: {email,required},
            apodo: {minLength: minLength(5),required},
        },
        validationGroup: ['dataCustomer.email','dataCustomer.apodo']
    },
    mounted() {
        var x = window.matchMedia("(max-width: 480px)")
        this.SizeSeecreen(x); // Call listener function at run time
        x.addListener(this.SizeSeecreen) // Attach listener function on state changes
    },
    methods: {
        start(){
            this.$confetti.start();
            var audio = new Audio('/theme/effects/winner.wav'); // path to file
            audio.play();
        },
        stop(){
            this.$confetti.stop()
        },
        setAnio(){
            this.dataCustomer.anio = this.$refs.anioSelected.selectedAnios;
        },
        setMes(){
            this.dataCustomer.mes = this.$refs.mesSelected.selectedMes;
        },
        setDia(){
            this.dataCustomer.dia = this.$refs.diaSelected.selectedDia;
        },
        saveClicked () {
            var img = this.$refs.vueavatar.getImageScaled()
            this.$refs.image.src = img.toDataURL();
            this.dataCustomer.avatar = img.toDataURL();
        },
        onImageReady () {
            var img = this.$refs.vueavatar.getImageScaled()
            this.scale = 1;
            this.rotation = 0;
            this.dataCustomer.avatar = img.toDataURL();
        },
        onComplete(){
           this.generateQrAndRegister();
        },
        setLoading(value) {
            this.loadingWizard = value;
        },
        handleValidation(isValid, tabIndex){
            console.log('Tab: '+tabIndex+ ' valid: '+isValid);
        },
        handleErrorMessage(errorMsg){
            this.errorMsg = errorMsg
        },
        validateTerminos(){ let $this = this;
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if(!$this.accept){
                        reject('Para continuar, por favor acepte, nuestros términos y condiciones!');
                    }else{
                        resolve(true);
                    }
                }, 2000)
            })
        },
        validateAsync() { let $this = this;
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if(this.$v.$invalid){
                        reject('Validar la información ingresada');
                    }
                    else{
                        if (this.dataCustomer.anio === null){
                            reject('Seleccione el año de nacimiento');
                        }
                        else if (this.dataCustomer.mes === null){
                            reject('Seleccione el mes de nacimiento');
                        }else if (this.dataCustomer.dia === null){
                            reject('Seleccione el día de nacimiento');
                        }else{
                            axios.post('/api/nuevoCliente/validateEmail',{
                                email: $this.dataCustomer.email
                            })
                                .then(function (r) {
                                    if (r.data.Success){
                                        axios.post('/api/nuevoCliente/validateApodo',{
                                            apodo: $this.dataCustomer.apodo
                                        })
                                            .then(function (rz) {
                                                if (rz.data.Success){
                                                    resolve(true);
                                                }
                                                else{
                                                    $this.dataCustomer.apodo = '';
                                                    reject(rz.data.Mensaje);
                                                }
                                            })
                                            .catch(function (error) {
                                                $this.$toastMessage('error','Error: ' + error,5000,'center');
                                            });
                                    }
                                    else{
                                        $this.dataCustomer.email = '';
                                        reject(r.data.Mensaje);
                                    }
                                })
                                .catch(function (error) {
                                    $this.$toastMessage('error','Error: ' + error,5000,'center');
                                });
                        }

                    }
                }, 2000)
            })
        },
        generateQrAndRegister(){ let $this = this;
            $this.showForm = false;
            $this.loadingRegister = true;
            $this.msgUno = true;
            setTimeout(() => {
                $this.msgUno = false;
                $this.msgDos = true;
            }, 2000);
            setTimeout(() => {
                $this.msgDos = false;
                $this.msgTres = true;
                axios.post('/api/nuevoCliente/saveCliente',{
                    data: $this.dataCustomer
                })
                    .then(function (r) {
                        if (r.data.Success){
                            setTimeout(() => {
                                $this.endProcess = true;
                                $this.qrUsr = r.data.Qr;
                                $this.imgUsr = r.data.Avatar;
                                $this.$toastMessage('success',r.data.Mensaje,4000,'center');
                                $this.start();
                                setTimeout($this.stop, 5000);
                            }, 1000);
                        }
                        else{
                            reject(r.data.Mensaje);
                        }
                    })
                    .catch(function (error) {
                        $this.$toastMessage('error','Error: ' + error + 'Contactar a Yogocup! :)',9000,'center');
                    });
            }, 4000);

        },
        SizeSeecreen(x) {
            if (x.matches) { // If media query matches
                this.$swal.fire({
                    title: 'HOLA!',
                    icon:'warning',
                    text: 'Te recomendamos usar tu dispositivo en forma horizontal, para una mejor experiencia.',
                    imageUrl: '/theme/img/brand/logo.png',
                    imageAlt: 'Aviso',
                })
            }
        },
        valData(){

        }
    }
}
</script>

<style scoped>
.mx-calendar-content .cell.active{
    background-color: #4a2c58 !important;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.wizard-btn {
    margin-bottom: 30px !important;
}

</style>
