<template>
    <div>
        <div class="text-center" v-if="shoLoad">
            <div class="spiner-example">
                <div class="sk-spinner sk-spinner-circle">
                    <div class="sk-circle1 sk-circle"></div>
                    <div class="sk-circle2 sk-circle"></div>
                    <div class="sk-circle3 sk-circle"></div>
                    <div class="sk-circle4 sk-circle"></div>
                    <div class="sk-circle5 sk-circle"></div>
                    <div class="sk-circle6 sk-circle"></div>
                    <div class="sk-circle7 sk-circle"></div>
                    <div class="sk-circle8 sk-circle"></div>
                    <div class="sk-circle9 sk-circle"></div>
                    <div class="sk-circle10 sk-circle"></div>
                    <div class="sk-circle11 sk-circle"></div>
                    <div class="sk-circle12 sk-circle"></div>
                </div>
                <b>REALIZANDO BUSQUEDA...</b>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="text-left">
                    <button class="btn btn-outline btn-success dim" type="button" v-show="!shoLoad"
                            @click="newTda()" data-effect="effect-just-me" data-toggle="modal" href="#myModal6"><i class="fa fa-plus"></i> ALTA SUCURSAL</button>
                </div>
            </div>
            <div class="col-md-4">
                <div class="text-center">
                    <button class="btn btn-outline btn-info dim" type="button" v-show="kcmidSearch && !shoLoad"
                            @click="GetDataTda(true)"><i class="fas fa-sync-alt"> ACTUALIZAR</i></button>
                    <button class="btn btn-outline btn-primary dim" type="button" v-show="!kcmidSearch && !shoLoad"
                            @click="GetDataTda(true)"><i class="fa fa-check"> CON IDTIENDA</i></button>
                </div>
            </div>
            <div class="col-md-4"></div>
        </div><br><br>
        <v-client-table :data="tableDataTiendas" :columns="columnsTiendas" :options="optionsTiendas" class="table-responsive" v-show="!shoLoad">
            <div slot="Tienda" slot-scope="props">
                {{props.row.Tienda}}<br>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <i class="fas fa-edit" style="color:blue;cursor:pointer;" v-if="props.row.EditTienda === '0'" @click="editNombreTienda(props.row.Id)"></i>
                        <i class="fas fa-times" style="color:red;cursor:pointer;" v-if="props.row.EditTienda === '1'" @click="cancelNombreTienda(props.row.Id)"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                        <i class="fas fa-save" style="color:green;cursor:pointer;" v-if="props.row.EditTienda === '1'" @click="saveNombreTienda(props.row.Id)"></i>
                    </div>
                </div>
                <div class="row" v-if="props.row.EditTienda === '1'">
                    <div class="col-md-12">
                        <textarea maxlength="120" placeholder="Nombre Tienda" v-model="newNombreTienda" class="form-control" rows="3"></textarea>
                    </div>
                </div>
            </div>
            <div slot="Mapa" slot-scope="props">
                <div class="text-center">
                    <a target="blank"
                       :href="'https://www.google.com/maps/search/?api=1&query='+props.row.Latitud+','+props.row.Longitud"><i
                        class="fa fa-globe"></i></a>
                    <div class="text-center">
                        <i v-if="props.row.Edit === '0'" class="fa fa-edit" style="color: #0909d7;cursor:pointer;" @click="editCoordenadas(props.row.Id)"></i>
                        <i v-if="props.row.Edit !== '0'" class="fa fa-save" style="color: green;cursor:pointer;" @click="saveCoordenadas(props.row.Id)"></i>
                        <i v-if="props.row.Edit !== '0'" class="fa fa-times" style="color: red;cursor:pointer;" @click="cancelCoordenadas(props.row.Id)"></i>
                    </div>
                    <h6><b>{{ props.row.Usuario }}</b></h6>
                </div>
            </div>
            <div slot="Ciudad" slot-scope="props">
                {{props.row.Ciudad}}<br>
                <i class="fa fa-edit" style="color: #0909d7;cursor:pointer;" data-effect="effect-just-me" data-toggle="modal" href="#myModal7" @click="editCamEdo(props.row.Id)"></i>
            </div>
            <div slot="Distribucion" slot-scope="props">
                {{props.row.Canal}}<br>
                {{props.row.Cadena}}<br>
                {{props.row.Formato}}<br>
                <i class="fa fa-edit" style="color: #0909d7;cursor:pointer;" data-effect="effect-just-me" data-toggle="modal" href="#myModal4" @click="editCanCadFmt(props.row.Id)"></i>
            </div>
            <div slot="Latitud" slot-scope="props">
                <div class="text-center">
                    <b>{{props.row.Latitud}}</b>
                </div>
                <div v-show="props.row.Edit !== '0'">
                    <input type="text" :value="props.row.Latitud" class="form-control" :ref="'lat'+props.row.Id">
                </div>
            </div>
            <div slot="Longitud" slot-scope="props">
                <div class="text-center">
                    <b>{{props.row.Longitud}}</b>
                </div>
                <div v-show="props.row.Edit !== '0'">
                    <input type="text" :value="props.row.Longitud" class="form-control" :ref="'lng'+props.row.Id">
                </div>
            </div>
            <div slot="IpApp" slot-scope="props">
                <div class="text-center">
                    <b>{{props.row.IpApp}}</b>
                </div>
                <div v-show="props.row.EditIpApp !== '0'">
                    <input type="text" :value="props.row.IpApp" class="form-control" :ref="'IpApp'+props.row.Id">
                </div><br>
                <div class="text-center">
                    <i v-if="props.row.EditIpApp === '0'" class="fa fa-edit" style="color: #0909d7;cursor:pointer;" @click="editIpApp(props.row.Id)"></i>
                    <i v-if="props.row.EditIpApp !== '0'" class="fa fa-save" style="color: green;cursor:pointer;" @click="saveIpApp(props.row.Id)"></i>
                    <i v-if="props.row.EditIpApp !== '0'" class="fa fa-times" style="color: red;cursor:pointer;" @click="cancelIpApp(props.row.Id)"></i>
                </div>
            </div>
            <div slot="IpPrint" slot-scope="props">
                <div class="text-center">
                    <b>{{props.row.IpPrint}}</b>
                </div>
                <div v-show="props.row.EditIpPrint !== '0'">
                    <input type="text" :value="props.row.IpPrint" class="form-control" :ref="'IpAppPrint'+props.row.Id">
                </div><br>
                <div class="text-center">
                    <i v-if="props.row.EditIpPrint === '0'" class="fa fa-edit" style="color: #0909d7;cursor:pointer;" @click="editIpAppPrint(props.row.Id)"></i>
                    <i v-if="props.row.EditIpPrint !== '0'" class="fa fa-save" style="color: green;cursor:pointer;" @click="saveIpAppPrint(props.row.Id)"></i>
                    <i v-if="props.row.EditIpPrint !== '0'" class="fa fa-times" style="color: red;cursor:pointer;" @click="cancelIpAppPrint(props.row.Id)"></i>
                </div>
            </div>
        </v-client-table>
        <div class="modal" id="myModal4" tabindex="-1" :class="{'show':shoModal}">
            <div class="modal-dialog modal-dialog-centered modal-lg " role="document" >
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Actualizar tienda</h6><button id="closeedit" aria-label="Close" class="close" data-dismiss="modal" type="button" @click="shoModal = !shoModal"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-2">
                                IDTIENDA:<br> <b>{{kcmid}}</b>
                            </div>
                            <div class="col-md-4">
                                Tienda:<br> <b>{{tienda}}</b>
                            </div>
                            <div class="col-md-2">
                                Canal:<br> <b>{{canal}}</b>
                            </div>
                            <div class="col-md-2">
                                Cadena:<br> <b>{{cadena}}</b>
                            </div>
                            <div class="col-md-2">
                                Formato:<br> <b>{{formato}}</b>
                            </div>
                        </div><br><br>
                        <div class="row">
                            <div class="col-md-4">
                                Canal:<br>
                                <v-select :multiple="false" :options="optionsCanales" :placeholder="'Selecciona Canal'" v-model="selectedCanales" @input="valueSelectedCanal()"></v-select>
                            </div>
                            <div class="col-md-4">
                                Cadena:<br>
                                <v-select :multiple="false" :options="optionsCadenas" :placeholder="'Selecciona Cadena'" v-model="selectedCadenas" @input="valueSelectedCadena()"></v-select>
                            </div>
                            <div class="col-md-4">
                                Formato:<br>
                                <v-select :multiple="false" :options="optionsFormatos" :placeholder="'Selecciona Formato'" v-model="selectedFormatos"></v-select>
                            </div>
                        </div><br>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveCambio">Cambiar <i class="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="myModal6" tabindex="-1" :class="{'show':shoModalAlta}">
            <div class="modal-dialog modal-dialog-centered modal-lg " role="document" >
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">ALTA DE SUCURSAL</h6><button id="close" aria-label="Close" class="close" data-dismiss="modal" type="button" @click="cancelAlta"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-4">
                                Estado:<br>
                                <v-select :multiple="false" :options="optionsEstados" :placeholder="'Selecciona Estado'" v-model="selectedEstados" @input="getCiudades()"></v-select>
                            </div>
                            <div class="col-md-4">
                                Ciudad:<br>
                                <v-select :multiple="false" :options="optionsCiudades" :placeholder="'Selecciona Ciudad'" v-model="selectedCiudades" @input="valueCiudades()"></v-select>
                            </div>
                            <div class="col-md-2"></div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-4">
                                Nombre Sucursal:<br>
                                <input type="text" maxlength="120" class="form-control" placeholder="Ingresa nombre de la sucursal" v-model="nombreSucursal">
                            </div>
                            <div class="col-md-4">
                                ID Sucursal:<br>
                                <input type="text" maxlength="120" class="form-control" placeholder="Ingresa id sucursal" v-model="idSucursal">
                            </div>
                            <div class="col-md-4">
                                Dirección:<br>
                                <textarea  maxlength="200" class="form-control" placeholder="Ingresa dirección de la sucursal" v-model="dirTienda" rows="2"></textarea>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-3">
                                Latitud Sucursal:<br>
                                <input type="number" class="form-control" placeholder="Ingresa latitud de la sucursal" v-model="latitudTienda">
                            </div>
                            <div class="col-md-3">
                                Longitud Sucursal:<br>
                                <input type="number"  class="form-control" placeholder="Ingresa longitud de la sucursal" v-model="longitudTienda">
                            </div>
                            <div class="col-md-3"></div>
                        </div>
                        <br>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click="cancelAlta"> Cancelar <i class="fa fa-times"></i></button>
                        <button type="button" class="btn btn-success" data-dismiss="modal" @click="saveAlta"> Registrar  <i class="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="myModal7" tabindex="-1" :class="{'show':shoModalChangeEstado}">
            <div class="modal-dialog modal-dialog-centered modal-lg " role="document" >
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">CAMBIO DE CIUDAD</h6><button id="closeCE" aria-label="Close" class="close" data-dismiss="modal" type="button" @click="cancelCambioEstado"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-4">
                                Tienda: <b>{{nombreSucursalChangeCiudad}}</b>
                            </div>
                            <div class="col-md-4">
                                Estado: <b class="tx-danger">{{estado}}</b><br>
                                <v-select :multiple="false" :options="optionsEstados" :placeholder="'Selecciona Estado'" v-model="selectedEstados" @input="getCiudades()"></v-select>
                            </div>
                            <div class="col-md-4">
                                Ciudad: <b class="tx-danger">{{ciudad}}</b><br>
                                <v-select :multiple="false" :options="optionsCiudades" :placeholder="'Selecciona Ciudad'" v-model="selectedCiudades" @input="valueCiudades()"></v-select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal" @click="cancelCambioEstado"> Cancelar <i class="fa fa-times"></i></button>
                        <button type="button" class="btn btn-success" @click="saveCE"> Actualizar  <i class="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "tiendas",
    data(){
        return{
            nombreSucursalChangeCiudad:"",
            newNombreTienda:'',
            edtNombreTienda:false,
            validarmapa:false,
            coordinates: null,
            idxSelectedUpdate:null,
            kcmidSearch:true,
            shoLoad:false,
            shoModal:false,
            shoModalAlta:false,
            shoModalChangeEstado:false,
            idSelected:0,
            message:"Hola mundo",
            totalEquipo:0,
            columnsTiendas: ['Id','Tienda','IdSucursal','Estado','Ciudad','Latitud','Longitud','Mapa','Direccion','Fecha','IpApp','IpPrint'],
            tableDataTiendas: [],
            optionsTiendas: {
                footerHeadings:false,
                filterByColumn:true,
                perPage:5,
                perPageValues: [2,5,10,15,25,45,50],
                filterable: ['Tienda','Ciudad','Estado','Direccion','IdSucursal'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {
                },
                columnsDropdown:false,
                headings: {
                    Id: '#',
                    IdSucursal: 'ID Sucursal',
                    Nombre: 'Nombre Completo',
                    Distribucion: 'Distribución',
                    IpApp: 'IP Aplicación',
                    IpPrint: 'IP Impresora',
                },
                columnsClasses: {
                    Id: 'text-center font-bold text-danger',
                    Nombre: 'text-center',
                    Agencia: 'text-center',
                    Ciudad: 'text-center',
                    Distribucion: 'text-center',
                    Area: 'text-center',
                    Tienda: 'text-justify',
                    Direccion: 'text-justify',
                    KCMID: 'text-center font-bold',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Buscando...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            },
            optionsEstados:[],
            selectedEstados:[],
            optionsCiudades:[],
            selectedCiudades:[],
            optionsCanales:[],
            optionsCanalesAlta:[],
            selectedCanales: [],
            optionsCadenas:[],
            optionsCadenasAlta:[],
            selectedCadenas: [],
            optionsFormatos:[],
            optionsFormatosAlta:[],
            selectedFormatos: [],
            selectedCanalesAlta: [],
            selectedCadenasAlta: [],
            selectedFormatosAlta: [],
            tienda:null,
            estado:null,
            ciudad:null,
            canal:null,
            cveentcan:null,
            cadena:null,
            cveentcad:null,
            formato:null,
            cveentfmt:null,
            kcmid:null,
            idx:0,
            nombreSucursal:'',
            dirTienda:'',
            idSucursal:'',
            latitudTienda:0,
            longitudTienda:0,
        };
    },
    mounted () { let $this = this;
        this.GetDataTda(this.kcmidSearch);
        axios
            .post('/api/sucursales/peticiones',{solicitud:4})
            .then(function (r){
                $this.optionsCanalesAlta = r.data;
            });
        axios
            .post('/api/sucursales/peticiones',{solicitud:17})
            .then(function (r){
                $this.optionsEstados = r.data;
            });
    },
    methods:{
        valueCiudades(){
            if (this.selectedCiudades === null){this.selectedCiudades=[]; }
        },
        getCiudades(){ let $this = this;
            this.selectedCiudades = [];
            if (this.selectedEstados === null){this.selectedEstados=[]; }
            else{
                axios
                    .post('/api/sucursales/peticiones',{solicitud:18,estado:this.selectedEstados.value})
                    .then(function (r){
                        $this.optionsCiudades = r.data;
                    });
            }

        },
        newTda(){
            this.shoModalAlta = !this.shoModalAlta;
        },
        cancelCambioEstado(){
            this.shoModalChangeEstado = !this.shoModalChangeEstado;
            document.getElementById('closeCE').click();
        },
        cancelAlta(){
            this.selectedEstados=[];
            this.selectedCiudades=[];
            this.nombreSucursal='';
            this.dirTienda='';
            this.idSucursal='';
            this.latitudTienda=0;
            this.longitudTienda=0;
            this.shoModalAlta = !this.shoModalAlta;
            document.getElementById('close').click();
        },
        saveCE(){
            if (this.selectedEstados === null || this.selectedEstados === [] || this.selectedEstados.label === '' || this.selectedEstados.length === 0){
                this.$toastMessage("error",'Seleccione el nuevo estado',3000,'top-right');
            }
            else if(this.selectedCiudades === null || this.selectedCiudades === [] || this.selectedCiudades.label === '' || this.selectedCiudades.length === 0){
                this.$toastMessage("error",'Seleccione la nueva ciudad',3000,'top-right');
            }else{ let $this = this;
                axios
                    .post('/api/sucursales/peticiones',{solicitud:24, cveenttda: this.idSelected, ciudad: this.selectedCiudades.value})
                    .then(function (r){
                        if (r.data.Success){
                            $this.tableDataTiendas[$this.idx].Estado = $this.selectedEstados.label;
                            $this.tableDataTiendas[$this.idx].Ciudad = $this.selectedCiudades.label;
                            $this.$toastMessage('success',r.data.Mensaje,3000,'top-right');
                            $this.shoModalChangeEstado = !$this.shoModalChangeEstado;
                            document.getElementById('closeCE').click();
                        }
                        else{
                            $this.$toastMessage('error',r.data.Mensaje,3000,'top-right');
                        }

                    },error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    });
            }
        },
        saveAlta(){ let $this = this;
            if (this.selectedEstados.length === 0){
                $this.$toastMessage('error',"Seleccione el estado",3000,'top-right');
            }
            else if (this.selectedCiudades.length === 0){
                $this.$toastMessage('error',"Seleccione la ciudad",3000,'top-right');
            }
            else if (this.nombreSucursal === '' || this.nombreSucursal.length < 4 ){
                $this.$toastMessage('error',"Ingrese un nombre para la sucursal valido",3000,'top-right');
            }
            else if (this.idSucursal === '' || this.idSucursal.length < 4 ){
                $this.$toastMessage('error',"Ingrese un ID para la sucursal valido",3000,'top-right');
            }
            else if (this.dirTienda === '' || this.dirTienda.length < 4 ){
                $this.$toastMessage('error',"Ingrese una dirección para la sucursal valida",3000,'top-right');
            }
            else if (this.latitudTienda === '' ){
                $this.$toastMessage('error',"Ingrese una latitud",3000,'top-right');
            }
            else if (this.longitudTienda === '' ){
                $this.$toastMessage('error',"Ingrese una longitud",3000,'top-right');
            }
            else{
                axios
                    .post('/api/sucursales/peticiones',{
                        solicitud:19,
                        ciudad:this.selectedCiudades.value,
                        sucursal:this.nombreSucursal,
                        latitud:this.latitudTienda,
                        longitud:this.longitudTienda,
                        dirtienda:this.dirTienda,
                        id:this.idSucursal})
                    .then(function (r){
                        if (r.data.Success){
                            $this.$toastMessage("success",r.data.Mensaje,3000,'top-end');
                            $this.GetDataTda(true);
                            $this.cancelAlta();
                        }

                    },error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    });
            }
        },
        valueSelectedCanal(){ let $this = this;
            this.selectedCadenas = [];
            this.selectedFormatos = [];
            axios
                .post('/api/sucursales/peticiones',{solicitud:5,canal:this.selectedCanales.value})
                .then(function (r){
                    $this.optionsCadenas = r.data;
                });
        },
        valueSelectedCanalAlta(){ let $this = this;
            this.selectedCadenasAlta = [];
            this.selectedFormatosAlta = [];
            this.optionsCadenasAlta = [];
            if (this.selectedCanalesAlta !== null){
                if(!(this.selectedCanalesAlta.length === 0))
                {
                    axios
                        .post('/api/sucursales/peticiones',{solicitud:5,canal:this.selectedCanalesAlta.value})
                        .then(function (r){
                            $this.optionsCadenasAlta = r.data;
                        });
                }
            }

        },
        valueSelectedCadena(){ let $this = this;
            axios
                .post('/api/sucursales/peticiones',{solicitud:6,cadena: this.selectedCadenas.value})
                .then(function (r){
                    $this.optionsFormatos = r.data;
                });
        },
        valueSelectedCadenaAlta(){ let $this = this;
            this.selectedFormatosAlta = [];
            this.optionsFormatosAlta = [];
            if (this.selectedCadenasAlta !== null){
                if(!(this.selectedCadenasAlta.length === 0))
                {
                    axios
                        .post('/api/sucursales/peticiones',{solicitud:6,cadena: this.selectedCadenasAlta.value})
                        .then(function (r){
                            $this.optionsFormatosAlta = r.data;
                        });
                }
            }else{this.selectedCadenasAlta = [];}
        },
        valueSelectedFormatosAlta(){
            if (this.selectedFormatosAlta === null){this.selectedFormatosAlta=[]; }
        },
        saveCambio(){ let $this = this;
            if (this.selectedCanales === null || this.selectedCanales === [] || this.selectedCanales.label === ''){
                $this.$toastMessage("error",'Seleccione el canal',3000,'top-end');
            }
            else if(this.selectedCadenas === null || this.selectedCadenas === [] || this.selectedCadenas.label === ''){
                $this.$toastMessage("error",'Seleccione la cadena',3000,'top-end');
            }
            else if(this.selectedFormatos === null || this.selectedFormatos === [] || this.selectedFormatos.label === ''){
                $this.$toastMessage("error",'Seleccione el formato',3000,'top-end');
            }
            else{
                axios
                    .post('/api/sucursales/peticiones',{solicitud:13, cveenttda: this.idSelected, formato: this.selectedFormatos.value})
                    .then(function (r){
                        if (r.data.Success){
                            $this.tableDataTiendas[$this.idx].Canal = $this.selectedCanales.label;
                            $this.tableDataTiendas[$this.idx].Cveentcan = $this.selectedCanales.value;
                            $this.tableDataTiendas[$this.idx].Cadena = $this.selectedCadenas.label;
                            $this.tableDataTiendas[$this.idx].Cveentcad = $this.selectedCadenas.value;
                            $this.tableDataTiendas[$this.idx].Formato = $this.selectedFormatos.label;
                            $this.tableDataTiendas[$this.idx].Cveentfmt = $this.selectedFormatos.value;
                            $this.tableDataTiendas[$this.idx].Distribucion = $this.selectedCanales.label + ' ' + $this.selectedCadenas.label + ' ' + $this.selectedFormatos.label;
                            $this.$toastMessage('success',r.data.Mensaje,3000,'top-end');
                            $this.shoModal = !$this.shoModal;
                            document.getElementById('closeedit').click();
                        }
                        else{
                            $this.$toastMessage('error',r.data.Mensaje,3000,'top-end');
                        }

                    },error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    });
            }
        },
        editCamEdo(idS){
            this.idSelected = idS;
            this.idx = this.findIdx(idS);
            this.nombreSucursalChangeCiudad = this.tableDataTiendas[this.idx].Tienda;
            this.canal = this.tableDataTiendas[this.idx].Canal
            this.cadena = this.tableDataTiendas[this.idx].Cadena
            this.formato = this.tableDataTiendas[this.idx].Formato
            this.estado = this.tableDataTiendas[this.idx].Estado
            this.ciudad = this.tableDataTiendas[this.idx].Ciudad
        },
        editCanCadFmt(idS){ let $this = this;
            this.idSelected = idS;
            this.idx = this.findIdx(idS);
            this.kcmid = this.tableDataTiendas[this.idx].KCMID;
            this.canal = this.tableDataTiendas[this.idx].Canal;
            this.cveentcan = this.tableDataTiendas[this.idx].Cveentcan;
            this.cadena = this.tableDataTiendas[this.idx].Cadena;
            this.cveentcad = this.tableDataTiendas[this.idx].Cveentcad;
            this.formato = this.tableDataTiendas[this.idx].Formato;
            this.cveentfmt = this.tableDataTiendas[this.idx].Cveentfmt;
            this.tienda = this.tableDataTiendas[this.idx].Tienda;
            this.selectedCanales.value = parseInt(this.cveentcan);
            this.selectedCanales.label = this.canal;
            axios
                .post('/api/sucursales/peticiones',{solicitud:4})
                .then(function (r){
                    $this.optionsCanales = r.data;
                    $this.getDataCadenas();
                    $this.shoModal = !$this.shoModal;
                },error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                });


        },
        getDataCadenas(){let $this = this;
            axios
                .post('/api/sucursales/peticiones',{solicitud:5,canal:this.selectedCanales.value})
                .then(function (r){
                    $this.optionsCadenas = r.data;
                    $this.selectedCadenas.value = parseInt($this.cveentcad);
                    $this.selectedCadenas.label = $this.cadena;
                    $this.getDataFormatos();
                },error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                });
        },
        getDataFormatos(){let $this = this;
            axios
                .post('/api/sucursales/peticiones',{solicitud:6,cadena:this.selectedCadenas.value})
                .then(function (r){
                    $this.optionsFormatos = r.data;
                    $this.selectedFormatos.value = parseInt($this.cveentfmt);
                    $this.selectedFormatos.label = $this.formato;
                },error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                });
        },
        GetDataTda(value){let $this = this;
            this.tableDataTiendas=[];
            this.shoLoad = true;
            this.kcmidSearch = value;
            axios
                .post('/api/sucursales/peticiones',{solicitud:1,claveusuario:this.cveuser,search:value})
                .then(function (r){
                    $this.tableDataTiendas = r.data; $this.shoLoad = false;
                },error => {
                    $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                });
        },
        changeCambio(Id){let $this = this;
            $this.$toastMessage("info","Realizando la petición seleccionada.",3000,'top-end');
            setTimeout(function(){
                var idx = $this.findIdx(Id);
                axios
                    .post('/api/sucursales/peticiones',{solicitud:2,tienda:Id})
                    .then(function (r){
                            $this.tableDataTiendas[idx].Cambio = 0;
                            $this.$toastMessage("success","Tienda disponible para actualizar coordenadas.",3000,'top-end');
                        },
                        error => {
                            $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                        });

            }, 1500);

        },
        editNombreTienda(Id){let $this = this;
            if (this.edtNombreTienda){
                $this.$toastMessage("error","Edición de nombre de tienda en proceso! termine el proceso para editar otro nombre de tienda.",3000,'top-center');
            }
            else{
                this.edtNombreTienda=true;
                var idx = $this.findIdx(Id);
                this.newNombreTienda = $this.tableDataTiendas[idx].Tienda;
                $this.tableDataTiendas[idx].EditTienda = '1';
            }

        },
        cancelNombreTienda(Id){let $this = this;
            this.edtNombreTienda=false;
            this.newNombreTienda ='';
            var idx = $this.findIdx(Id);
            $this.tableDataTiendas[idx].EditTienda = '0';
        },
        saveNombreTienda(Id){ let $this = this;
            $this.$toastMessage("info","Realizando la petición seleccionada.",3000,'top-end');
            var idx = $this.findIdx(Id);
            setTimeout(function(){
                axios.post('/api/sucursales/peticiones',{solicitud:20,tienda:Id, nombre:$this.newNombreTienda})
                    .then(function (r){
                        $this.tableDataTiendas[idx].EditTienda = '0';
                        $this.tableDataTiendas[idx].Tienda = $this.newNombreTienda;
                        $this.newNombreTienda ='';
                        $this.edtNombreTienda=false;
                        $this.$toastMessage("success","Tienda actualizada correctamente.",3000,'top-end');
                    },error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    });

            }, 1500);
        },
        editCoordenadas(Id){let $this = this;
            var idx = $this.findIdx(Id);
            $this.tableDataTiendas[idx].Edit = '1';
        },
        editIpApp(Id){let $this = this;
            var idx = $this.findIdx(Id);
            $this.tableDataTiendas[idx].EditIpApp = '1';
        },
        editIpAppPrint(Id){let $this = this;
            var idx = $this.findIdx(Id);
            $this.tableDataTiendas[idx].EditIpPrint = '1';
        },
        cancelCoordenadas(Id){let $this = this;
            var idx = $this.findIdx(Id);
            $this.tableDataTiendas[idx].Edit = '0';
        },
        cancelIpApp(Id){let $this = this;
            var idx = $this.findIdx(Id);
            $this.tableDataTiendas[idx].EditIpApp = '0';
        },
        cancelIpAppPrint(Id){let $this = this;
            var idx = $this.findIdx(Id);
            $this.tableDataTiendas[idx].EditIpPrint = '0';
        },
        saveCoordenadas(Id){let $this = this;
            var lat = this.$refs['lat'+Id].value;
            var lng = this.$refs['lng'+Id].value;
            $this.$toastMessage("info","Realizando la petición seleccionada.",3000,'top-end');
            setTimeout(function(){
                var idx = $this.findIdx(Id);
                axios
                    .post('/api/sucursales/peticiones',{solicitud:3,tienda:Id, latitud:lat, longitud:lng})
                    .then(function (r){
                        $this.tableDataTiendas[idx].Cambio = 1;
                        $this.tableDataTiendas[idx].Edit = '0';
                        $this.tableDataTiendas[idx].Usuario = r.data.Usuario;
                        $this.tableDataTiendas[idx].Latitud = lat;
                        $this.tableDataTiendas[idx].Longitud = lng;
                        $this.$toastMessage("success","Tienda actualizada correctamente.",3000,'top-end');
                    },error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    });

            }, 1500);
        },
        saveIpApp(Id){let $this = this;
            var newIpApp = this.$refs['IpApp'+Id].value;
            $this.$toastMessage("info","Realizando la petición seleccionada.",3000,'top-end');
            setTimeout(function(){
                var idx = $this.findIdx(Id);
                axios
                    .post('/api/sucursales/peticiones',{solicitud:26,tienda:Id, ip:newIpApp})
                    .then(function (r){

                        $this.tableDataTiendas[idx].EditIpApp = '0';
                        $this.tableDataTiendas[idx].IpApp = newIpApp;
                        $this.$toastMessage("success","Tienda actualizada correctamente.",3000,'top-end');
                    },error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    });

            }, 1500);
        },
        saveIpAppPrint(Id){let $this = this;
            var newIpApp = this.$refs['IpAppPrint'+Id].value;
            $this.$toastMessage("info","Realizando la petición seleccionada.",3000,'top-end');
            setTimeout(function(){
                var idx = $this.findIdx(Id);
                axios
                    .post('/api/sucursales/peticiones',{solicitud:27,tienda:Id, ip:newIpApp})
                    .then(function (r){

                        $this.tableDataTiendas[idx].EditIpPrint = '0';
                        $this.tableDataTiendas[idx].IpPrint = newIpApp;
                        $this.$toastMessage("success","Tienda actualizada correctamente.",3000,'top-end');
                    },error => {
                        $this.$toastMessage('error','Error: ' + error +' Por favor envia este error al área de soporte!',9000,'center');
                    });

            }, 1500);
        },
        findIdx(findId){let $this = this;
            var idn = 0;
            var a = 0;
            for(a;a<$this.tableDataTiendas.length;a++){
                if ($this.tableDataTiendas[a].Id === findId){
                    idn = a;
                }
            }
            return idn;
        },

    }
}
</script>

<style scoped>

</style>
