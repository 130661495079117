<template>
    <div>
        <v-select
            :multiple="false"
            :options="optionsTienda"
            :placeholder="'Selecciona '+placeholderm"
            append-to-body
            :calculate-position="withPopper"
            v-model="selectedTienda"
            @input="setCambio"
        ></v-select>
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core'

export default {
    name: "sucursales",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedTienda:null,
            optionsTienda:[],
            placement: 'top'
        }
    },
    methods:{
        getSucursales(){ let $this = this;
            this.selectedTienda = null;
            axios
                .post('/api/sucursales/peticiones', {solicitud: 25})
                .then(function (r){
                    $this.optionsTienda = r.data;
                });
        },
        setCambio(){
            this.$emit('cambioTienda');
        },
        withPopper(dropdownList, component, { width }) {
        /**
         * We need to explicitly define the dropdown width since
         * it is usually inherited from the parent with CSS.
         */
        dropdownList.style.width = width

        /**
         * Here we position the dropdownList relative to the $refs.toggle Element.
         *
         * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
         * the dropdownList overlap by 1 pixel.
         *
         * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
         * wrapper so that we can set some styles for when the dropdown is placed
         * above.
         */
        const popper = createPopper(component.$refs.toggle, dropdownList, {
            placement: this.placement,
            modifiers: [
            {
                name: 'offset',
                options: {
                offset: [0, -1],
                },
            },
            {
                name: 'toggleClass',
                enabled: true,
                phase: 'write',
                fn({ state }) {
                component.$el.classList.toggle(
                    'drop-up',
                    state.placement === 'top'
                )
                },
            },
            ],
        })

        /**
         * To prevent memory leaks Popper needs to be destroyed.
         * If you return function, it will be called just before dropdown is removed from DOM.
         */
        return () => popper.destroy()
        },
    },
    mounted() {

    }
}
</script>

<style scoped>

</style>
