<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <date-picker v-model="time" range @change="getData"></date-picker>
            </div>
            <div class="col-md-3">
            </div>
            <div class="col-md-3">
                <button type="button" class="btn ripple btn-outline-success" @click="ExportFile"><i class="far fa-file-excel"></i> Exportar</button>
                <div class="row" v-if="showLoad">
                    <div class="col-md-12 text-center"><br><br>
                        <img alt="loadData" src="/theme/img/loads/spiralload.gif" class="" height="40px"/>
                        <p><b>Procesando información...</b></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" v-show="showLoadData">
                <v-client-table :data="tableData" :columns="columns" :options="options" ref="ventasTable">
                </v-client-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "tablaVentas",
    data(){
        return{
            showLoad:false,
            showLoadData:false,
            time:null,
            columns:['Id','Cajero','Tienda','Ciudad','Cliente','Productos','VentaReal','Descuento','Total','CreatedAt'],
            tableData: [],
            options: {
                // see the options API
                footerHeadings:false,
                filterByColumn:true,
                perPage:10,
                perPageValues: [10,15,25,45,50],
                filterable: ['Cajero','Tienda','Cliente','Ciudad'],
                sortable: ['Cajero','Tienda','Cliente','Productos','VentaReal','Descuento','Total','CreatedAt','Ciudad'],
                sortIcon:
                    { base:'fontawesome', up:'fas fa-long-arrow-alt-up', down:'fas fa-long-arrow-alt-down', is:'fas fa-sort' }
                ,
                listColumns: {},
                headings: {
                    VentaReal: 'Venta Bruta',
                    Total: 'Venta Neta',
                    CreatedAt: 'Fecha - Hora',
                },
                columnsClasses: {
                    Id: 'text-center tx-bold tx-danger',
                    Descuento: 'text-center tx-bold tx-info',
                    Tienda: 'text-justify',
                    CreatedAt: 'text-justify',
                    VentaReal: 'text-justify tx-bold',
                    Total: 'text-justify tx-bold',
                    Cliente: 'text-center',
                    Productos: 'text-center',
                },
                texts: {
                    count: "Mostrando {from} al {to} de {count} registros|{count} registros|Un registro",
                    first: 'Primero',
                    last: 'Último',
                    filter: "Filtro:",
                    filterPlaceholder: "Buscar:",
                    limit: "Registros:",
                    page: "Pagína:",
                    noResults: "No hay resultados",
                    filterBy: "Filtrar por {column}",
                    loading: 'Recopilando información, por favor espere...',
                    defaultOption: 'Todos',
                    //defaultOption: 'Elige {column}',
                    columns: 'Columnas'
                },
            }
        }
    },
    methods:{
        async ExportFile(){
            await this.$peticionesApiFilesDownload('/api/Ventas/exportExcel','VentasRealizadas',this.tableData);
        },
        async getData(){
            this.showLoadData = false;
            this.showLoad = true;
            this.tableData = [];
            console.log(this.time);
            var response = await this.$peticionesApi('/api/Ventas/getVentas',{date: this.time});
            if (response !== null){
                this.showLoadData = true;
                this.showLoad = false;
                this.tableData=response.Data;
            }else{
                this.showLoadData = false;
                this.showLoad = false;
                this.tableData = [];
            }
        }
    }
}
</script>

<style scoped>

</style>
