<template>
    <div>
        <div class="card custom-card">
            <div class="modal-header">
                <div class="row header-container">
                    <div class="col-md-4">
                        <h6 class="modal-title">REALIZAR VENTA </h6>
                    </div>
                    <div class="col-md-4">
                        <h6 class="modal-title text-left"> {{dataUser}}</h6>
                    </div>
                    <div class="col-md-4">
                        <div v-if="tieneCupon">
                            <div class="row">
                                <div class="col-md-8">
                                    <p-check class="p-icon p-fill p-tada" color="success" v-model="aplicaCupon" @change="validateTotal" style="font-size: 20px">
                                        <i slot="extra" class="icon fas fa-gift"></i>
                                        APLICAR CUPÓN
                                    </p-check>
                                </div>
                                <div class="col-md-4">
                                    <h5 class="text-left tx-bold tx-success" v-if="cupones.length>0"> {{ cupones[0]["Descuento"]}}</h5>
                                </div>
                            </div>
                        </div>
                        <button @click="registerVenta" type="button" class="btn ripple btn-success btn-with-icon" data-dismiss="modal" v-show="articulos.length>0" >
                            <i class="fa fa-money-bill"></i>  Registrar Venta
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div v-if="clienteFind">
                    <div class="row">
                        <div class="col-md-4">
                            <h3><b @click="getTicket">Productos:</b></h3>
                        </div>
                        <div class="col-md-4">
                            <h3><b>Articulos: {{articulos.length}}</b></h3>
                        </div>
                        <div class="col-md-4">
                            <h3><b>Total: ${{total < 0 ? 0 : total}}</b></h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="">
                                <div class="card custom-card" v-for="items in productos">
                                    <div class="card-body dash1" v-show="items.Stt">
                                        <div class="">
                                            <p class="mb-1 tx-inverse">{{items.Nombre}}</p>
                                            <div class="ml-auto">
                                                <i class="fas fa-cookie fs-20 text-primary"></i>
                                            </div>
                                        </div>
                                        <div>
                                            <h3 class="dash-25">${{items.Precio}}</h3>
                                        </div>
                                        <div >
                                            <button v-on:click="addPto(items.Id)">
                                                <img alt="Imagen producto" height="80px" :src="items.Pathimg" style="cursor:pointer" >
                                            </button>
                                        </div>
                                        <div class="expansion-label">
                                            <span class="text-muted">{{items.Gramaje }} gr.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="">
                                <div class="card custom-card" v-for="items in articulos">
                                    <div class="card-body dash1">
                                        <div class="">
                                            <p class="mb-1 tx-inverse">{{items.Nombre}}</p>
                                            <div class="ml-auto">
                                                <i class="fas fa-trash" style="color: red" @click="removeItems(items.Id)"></i>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <h4 class="dash-25">${{items.Precio}}</h4>
                                            </div>
                                            <div class="col-md-6">
                                                <h4 class="dash-25">Cnt: {{items.Cantidad}}</h4>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-9">
                                                <img alt="Imagen producto" height="80px" :src="items.Pathimg" style="cursor:pointer">
                                            </div>
                                            <div class="col-md-3">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <h3><i class="fas fa-plus" style="color: green;cursor: pointer" @click="addCnt(items.Id)"></i></h3>
                                                    </div>
                                                    <div class="col-md-6" v-show="items.Cantidad > 1">
                                                        <h3><i class="fas fa-minus" style="color: red;cursor: pointer" @click="rmvCnt(items.Id)"></i></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="expansion-label ">
                                            <span class="text-muted">{{items.Gramaje }} gr.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                </div>
                <div v-else>
                    <div class="text-center">
                        <b class="tx-danger">Para realizar la venta, es necesario ser un cliente registrado</b>
                        <div v-if="lodingCupones">
                            <div class="lds-ripple"><div></div><div></div></div><br>
                            <span class="text-sm">Buscando Cupones...</span>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="text-center">
                                    <b>Busqueda por Apodo - Cumpleaños</b>
                                </div>
                                <select-apodo :placeholderm="'Apodo - Cumpleaños'" ref="apodoUser" v-on:cambioApodo="getUserApodo"></select-apodo>
                            </div>
                            <div class="col-md-6">
                                <div class="text-center">
                                    <b>Busqueda por Email - Teléfono</b>
                                </div>
                                <select-email :placeholderm="'Email - Teléfono'" ref="emailUser" v-on:cambioEmail="getUserEmail"></select-email>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-4"></div>
                            <div class="col-md-4" v-show="searchFind">
                                <button class="btn ripple btn-primary btn-with-icon" @click="addCliente">
                                    <i class="typcn typcn-user-add"></i> Agregar Cliente Para Venta
                                </button>
                            </div>
                            <div class="col-md-4"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </div>
    </div>

</template>

<script>
import JQuery from 'jquery'
let $ = JQuery
export default {
    name: "ventas",
    props:{
        ipApp:{
            required: true,
            default: '0.0.0.0',
            type: String
        },
        ipPrint:{
            required: true,
            default: '0.0.0.0',
            type: String
        },
        sucursalId:{
            required: true,
            default: 0,
            type: Number
        }
    },
    data(){
        return{
            shoModalAlta:false,
            productos:[],
            articulos:[],
            total:0,
            clienteFind:false,
            searchFind:false,
            dataUser:'',
            lodingCupones:false,
            cupones:[],
            dataVenta:[],
            aplicaCupon:true,
            tieneCupon:false,
            descuento:0,
            idCliente:0,
            pathPdf:'',
            contentIframe:'adfasdfasdf <h1>hola mundo</h1>',
            aframe: null,
            ua:null,
            isAndroid:false,
            printer:''
        }
    },
    mounted() {
        this.getProductos();
        this.aframe = window.frames["printf"];

        this.ua = navigator.userAgent.toLowerCase();
        this.isAndroid = this.ua.indexOf("android") > -1
    },
    methods:{
        async ImprimirTicket() {
            let $this = this;
            axios.post(`https://jellyfish-app-i3pof.ondigitalocean.app/printer`,{
                    ip:this.ipPrint,
                    SucursalId:this.sucursalId,
                    DataTicket:[this.dataVenta[0]],
                    Productos:this.articulos,
                    idCupon:this.cupones,
                }
            )
                .then(function (r) {
                    if (r.data.Success) {
                        $this.productos = r.data.Data;
                        $this.cupones = [];
                    }
                })
                .catch(function (error) {
                    $this.$toastMessage('error','Error: ' + error,5000,'center');
                });

        },
        async getTicket(){
            if (!this.aplicaCupon){
                this.cupones=[];
            }
            let response = await this.$peticionesApiTicket('/api/Ventas/getTicket',this.articulos,this.dataVenta,this.cupones);
            if (response !== null){
                // this.ImprimirTicket();
                this.cancelVenta();
            }
        },
        async registerVenta(){let $this = this;
            this.$swal({
                title: "Registrando venta",
                text: "Espere por favor.",
                imageUrl: "/theme/img/loads/loadfile.gif",
                showConfirmButton: false
            });
            var response = await this.$peticionesApi('/api/Ventas/registrarVenta',{
                productos: this.articulos, total: this.total, cupon: this.aplicaCupon, descuento: this.descuento,
                cliente: this.idCliente, idCupon: this.cupones
            });
            if (response !== false){
                this.$toastMessage("success", response.Mensaje, 3000, 'top-right');
                this.dataVenta = response.DataTicket;
                this.getTicket();
            }


        },
        validateTotal(){ let $this = this;
            if (this.cupones.length>0){
                if (this.aplicaCupon){
                    this.descuento = this.cupones[0]["Descuento"];
                }else{
                    this.descuento = 0;
                    this.aplicaCupon = false;
                }
                var t = 0;
                var i = 0;
                this.articulos.forEach(function (key){
                    t = t +( $this.articulos[i].Cantidad * $this.articulos[i].Precio );
                    i++;
                });
                this.total= (t - this.descuento).toFixed(2);
            }

        },
        start () {
            this.$confetti.start();
            var audio = new Audio('/theme/effects/premiolady.mpeg'); // path to file
            audio.play();
        },
        stop () {
            this.$confetti.stop()
        },
        cancelVenta(){
            this.clienteFind = false;
            this.searchFind = false;
            this.dataUser = '';
            this.productos =[];
            this.articulos =[];
            this.cupones =[];
            this.aplicaCupon = false;
            this.tieneCupon = false;
            this.total = 0;
            this.getProductos();
        },
        addCliente(){let $this = this;
            this.searchFind = false;
            this.clienteFind = true;
            if (this.cupones.length>0){
                this.aplicaCupon = true;
                $this.start();
                setTimeout(function (){
                    $this.stop()
                }, 6000);
            }
            this.tieneCupon = this.cupones.length>0;
            this.aplicaCupon = this.cupones.length>0;
        },
        async getUserApodo(){
            this.lodingCupones = true;
            this.dataUser = this.$refs.apodoUser.selectedApodo.data;
            this.idCliente = this.$refs.apodoUser.selectedApodo.value;
            var response = await this.$peticionesApi('/api/Clientes/getCuponPorCliente',{'cliente': this.idCliente});
            this.cupones = response.Data;
            this.searchFind = true;
            this.lodingCupones = false;
        },
        async getUserByQr(cliente){
            this.lodingCupones = true;
            var response = await this.$peticionesApi('/api/Clientes/getCuponPorCliente',{'cliente': cliente});
            this.cupones = response.Data;
            this.searchFind = true;
            this.lodingCupones = false;
            this.idCliente = cliente;
            this.addCliente();
        },
        async getUserEmail(){
            this.lodingCupones = true;
            this.searchFind = true;
            this.dataUser = this.$refs.emailUser.selectedEmail.data;
            this.idCliente = this.$refs.emailUser.selectedEmail.value;
            var response = await this.$peticionesApi('/api/Clientes/getCuponPorCliente',{'cliente': this.idCliente});
            this.cupones = response.Data;
            this.searchFind = true;
            this.lodingCupones = false;
        },

        getProductos(){ let $this = this;
            axios.post('/api/Productos/getDataActive')
                .then(function (r) {
                    if (r.data.Success) {
                        $this.productos = r.data.Data;
                    }
                })
                .catch(function (error) {
                    $this.$toastMessage('error','Error: ' + error,5000,'center');
                });
        },
        removeItems(cve){ let $this = this;
            var idx = this.getIdxPtos(cve);
            var idr = this.getIdxArt(cve);
            this.productos[idx].Stt = true;
            this.articulos.splice(idr,1);
            var t = 0;
            var i = 0;
            this.articulos.forEach(function (key){
                t = t +( $this.articulos[i].Cantidad * $this.articulos[i].Precio );
                i++;
            });
            this.total= (t - this.descuento).toFixed(2);
        },
        addCnt(cve){ let $this = this;
            var idx = this.getIdxArt(cve);
            this.articulos[idx].Cantidad = parseInt(this.articulos[idx].Cantidad) + 1;
            var t = 0;
            var i = 0;
            this.articulos.forEach(function (key){
                t = t +( $this.articulos[i].Cantidad * $this.articulos[i].Precio );
                i++;
            });
            this.total = (t - this.descuento).toFixed(2);
        },
        rmvCnt(cve){ let $this = this;
            var idx = this.getIdxArt(cve);
            this.articulos[idx].Cantidad = parseInt(this.articulos[idx].Cantidad) - 1;
            var t = 0;
            var i = 0;
            this.articulos.forEach(function (key){
                t = t +( $this.articulos[i].Cantidad * $this.articulos[i].Precio );
                i++;
            });
            this.total= (t - this.descuento).toFixed(2);
        },
        addPto(cve){
            var idx = this.getIdxPtos(cve);
            this.productos[idx].Stt = false;
            this.articulos.push(this.productos[idx]);
            this.total = this.total + this.productos[idx].Precio;
            this.validateTotal();
        },
        getIdxPtos(clave){let $this = this;
            var idx = 0;
            for (var i = 0; i< this.productos.length; i++){
                if (clave === $this.productos[i]['Id']){
                    idx = i;
                }
            }
            return idx;
        },
        getIdxArt(clave){let $this = this;
            var idx = 0;
            for (var i = 0; i< this.articulos.length; i++){
                if (clave === $this.articulos[i]['Id']){
                    idx = i;
                }
            }
            return idx;
        },
    },

}
</script>

<style scoped>
.left {
    text-align: center;
    height:440px;
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow-y: scroll;
}

.header-container {
    width: 100%;
}
</style>
