<template>
    <div>
        <!-- Modal effects -->
        <div class="modal" id="modalPromociones" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Promociones Usuario <i class="fa fa-user"></i><i class="fa fa-gift"></i></h6>
                        <button ref="closeModalPromociones" class="btn ripple btn-secondary text-right btn-with-icon" data-dismiss="modal" type="button">Cancelar &nbsp;&nbsp;<i class="fa fa-times"></i></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-3">
                                <h5 style="cursor: pointer" @click="enviarOtroCupon=true;cupon=0"><b>Enviar Otro Cupon :)</b></h5>
                            </div>
                            <div class="col-md-9" v-show="enviarOtroCupon">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Ingresa monto del cupon:</label>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="number" class="form-control" max="200" min="0" v-model="cupon">
                                    </div>
                                    <div class="col-md-4">
                                        <i class="fa fa-times" style="cursor: pointer;color: red" @click="enviarOtroCupon=false;cupon=0"> Cancelar</i>
                                        <i class="fa fa-save" style="cursor: pointer;color: #0c7815" @click="sendCupon()"> Guardar</i>
                                    </div>
                                </div>
                            </div>
                        </div><br>
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-10">
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                        <th>Premio</th>
                                        <th>Sellos</th>
                                        <th>Descuento</th>
                                        <th>Estatus</th>
                                        <th>Vencimiento</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="items in dataCupones">
                                            <td class="text-justify">{{items.Premio}}</td>
                                            <td class="text-center tx-bold">{{items.EnLaVenta}}</td>
                                            <td class="text-center">
                                                <span v-if="items.Descuento !== 0">${{items.Descuento}}</span>
                                                <span v-else-if="items.Descuentos === '0.00'">100 grms.</span>
                                                <span v-else>${{items.Descuentos}}</span>
                                            </td>
                                            <td class="text-center">
                                                <span class="badge badge-danger" v-if="items.Estatus === 0">Pendiente</span>
                                                <span class="badge badge-success" v-if="items.Estatus === 1">Canjeado</span>
                                                <span class="badge badge-warning" v-if="items.Estatus === 2">Caducado</span>
                                            </td>
                                            <td class="text-center tx-bold">
                                               {{items.FechaVencimiento}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Modal effects-->
    </div>
</template>

<script>
export default {
    name: "clientePromociones",
    data(){
        return{
            dataCupones:[],
            enviarOtroCupon:false,
            cupon:0
        }
    },
    methods:{
        async getData(id){
            this.dataCupones = [];
            var promociones = await this.$peticionesApi('/api/Clientes/getPromocionesCliente',{cliente:id});
            this.dataCupones = promociones.Data;

        },
        async sendCupon(){
            if (this.cupon>200){
                this.$toastMessage('error',"El cupon no puede ser mayor a $200.00",4000,'center');
            }
            else if(this.cupon <= 0){
                this.$toastMessage('error',"El cupon no puede ser menor a $0",4000,'center');
            }
            else{
                var response = await this.$peticionesApi("/api/Clientes/sendCuponPorCliente",{cupon: this.cupon,cliente: this.dataCupones[0]["Cliente"]});
                this.$toastMessage('success',response.Mensaje+"",4000,'center');
                this.enviarOtroCupon = false;
                this.cupon = 0;
                this.getData(this.dataCupones[0]["Cliente"])
            }
        }
    }
}
</script>

<style scoped>

</style>
