<template>
    <div>
        <v-select :multiple="false" :options="optionsMes" :placeholder="'Selecciona '+placeholderm"
                  v-model="selectedMes" @input="setCambio"></v-select>
    </div>
</template>

<script>
export default {
    name: "mes",
    props:{
        placeholderm:String,
    },
    data(){
        return{
            selectedMes: null,
            optionsMes:[{label:'Enero',value:'01'},{label:'Febrero',value:'02'},{label:'Marzo',value:'03'},{label:'Abril',value:'04'},
                {label:'Mayo',value:'05'},{label:'Junio',value:'06'},{label:'Julio',value:'07'},{label:'Agosto',value:'08'},
                {label:'Septiembre',value:'09'},{label:'Octubre',value:'10'},{label:'Noviembre',value:'11'},{label:'Diciembre',value:'12'}]
        }
    },
    mounted() {

    },
    methods:{
        setCambio(){
            this.$emit('cambioMes');
        }

    }
}
</script>

<style scoped>

</style>
