<template>
    <div class="main-sidebar-body">
        <ul class="nav">
            <li class="nav-label">Dashboard</li>
            <li class="nav-item active show">
                <a class="nav-link" href="/" v-if="$rol(['Administrador','Desarrollador','Usuario'])"><i class="fe fe-airplay"></i><span class="sidemenu-label">Dashboard</span></a>
            </li>
            <li class="nav-item active show" v-if="$rol(['Administrador','Desarrollador','Usuario'])">
                <a class="nav-link with-sub" href=""><i class="fe fe-layers"></i><span class="sidemenu-label">Administración</span><i class="angle fe fa-external-link-alt"></i></a>
                <ul class="nav-sub">
                    <li class="nav-sub-item">
                        <a class="nav-sub-link" href="/admin/usuarios"><i class="fas fa-users-cog"></i>&nbsp;&nbsp; Usuarios</a>
                    </li>
                    <li class="nav-sub-item">
                        <a class="nav-sub-link" href="/admin/sucursales"><i class="fas fa-store"></i>&nbsp;&nbsp; Sucursales</a>
                    </li>
                    <li class="nav-sub-item">
                        <a class="nav-sub-link" href="/admin/clientes"><i class="fas fa-user-astronaut"></i>&nbsp;&nbsp; Clientes</a>
                    </li>
                    <li class="nav-sub-item">
                        <a class="nav-sub-link" href="/admin/productos"><i class="fas fa-boxes"></i>&nbsp;&nbsp; Productos</a>
                    </li>
                    <li class="nav-sub-item">
                        <a class="nav-sub-link" href="/admin/ventas"><i class="fas fa-money-bill"></i>&nbsp;&nbsp; Ventas</a>
                    </li>
                </ul>
            </li>
            <li class="nav-item active show" v-if="$rol(['Cajero'])">
                <a class="nav-link with-sub" href=""><i class="fas fa-money-bill-wave-alt"></i><span class="sidemenu-label">Ventas</span><i class="angle fe fa-external-link-alt"></i></a>
                <ul class="nav-sub">
                    <li class="nav-sub-item">
                        <a class="nav-sub-link" href="/cajero/ventas"><i class="fas fa-book-reader"></i>&nbsp;&nbsp; Mis Ventas</a>
                    </li>

                </ul>
            </li>
        </ul>
        <br><br>
    </div>
</template>

<script>
export default {
    name: "sidemenu"
}
</script>

<style scoped>

</style>
